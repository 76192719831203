import { domains, getSubDomains } from "domains";
import { EditorAssetsType } from "./EditorPageType";
import { mcdonaldEditorAssets } from "./mcdonald";
import { onelifearcEditorAssets } from "./onelifearc";

var EditorTheme: EditorAssetsType;

if (process.env.NODE_ENV === "production") {
  switch (getSubDomains()) {
    case domains.mcdonald:
      EditorTheme = mcdonaldEditorAssets;
      break;
    case domains.lifearc:
      EditorTheme = onelifearcEditorAssets;
  }
}

if (process.env.NODE_ENV === "development") {
  EditorTheme = onelifearcEditorAssets;
  // EditorTheme = mcdonaldEditorAssets
}

export default EditorTheme;
