import { Frame, useEditor } from "@craftjs/core";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";

import { Theme } from "theme";
import { TPlaybook } from "type";
import Template from "./templates";

// const A4_SIZE_IN_PIXEL = {
//   width: 1754,
//   height: 1250,
// };

// craftjs representation of <Element id="root" is="div" />, serialised
// we deserialise this to clear the screen, since it's a hassle to delete nodes individually
const clear = `{"ROOT":{"type":"div","isCanvas":false,"props":{"id":"root"},"displayName":"div","custom":{},"hidden":false,"nodes":[],"linkedNodes":{}}}`;

type TPages = TPlaybook["content"]["pages"];

interface SceneProps {
  pageNumber: number;
  activePage: TPages[number];
  onChange: (id: string, data: string) => void;
}

const Scene: React.FC<SceneProps> = ({ pageNumber, activePage, onChange }) => {
  const { query, actions } = useEditor();
  const classes = useStyles();

  useEffect(() => {
    if (activePage) {
      if (activePage.data) {
        actions.deserialize(activePage.data);
      } else {
        actions.deserialize(clear);
        const nodeTree = query
          .parseReactElement(<Template type={activePage.template} />)
          .toNodeTree();
        actions.addNodeTree(nodeTree, "ROOT");
      }
    }
    return () => {
      if (activePage) onChange(activePage.id, query.serialize());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, activePage, onChange]);

  return (
    <div className={classes.frame}>
      <Frame data={clear} />
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  frame: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width: 1754px)": {
      transform: "scale(1)",
    },

    "@media (max-width: 1500px)": {
      transform: "scale(0.9)",
    },
    "@media (max-width: 1400px)": {
      transform: "scale(0.8)",
    },
    "@media (max-width: 1131px)": {
      transform: "scale(1)",
    },
    "@media (max-width: 800px)": {
      transform: "scale(0.7)",
    },
  },
}));

export default Scene;
