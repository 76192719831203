import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";

import { checkMultiSiteSetup } from "./multiSite";

const startApp = async () => {
  try {
    await checkMultiSiteSetup();
  } catch (err) {
    console.warn(err);
  } finally {
    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById("root"),
    );
  }
};

startApp();
