import { createUseStyles } from "react-jss";
import { Element, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";

import cover from "images/onelifeArc/cover.png";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#003950",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flexShrink: 0,
  },
  inner: {
    position: "absolute",
    left: 45,
    top: 370,
    height: 180,
    width: 400,
    zIndex: 10,
    display: "flex",

    "& > *": {
      justifyContent: "center",
      zIndex: 9,
    },
  },
  cover: {
    position: "absolute",
    height: "100%",
  },
}));

const Cover: UserComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <Element id="cover-dropzone" is={Dropzone} canvas />
      </div>
      <img src={cover} className={classes.cover} alt="one life arc logo" />
    </div>
  );
};

export default Cover;
