import { createUseStyles } from "react-jss";
import { Element, useNode, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";
import SettingsButton, {
  SettingsButtonGroup,
} from "../../settings/SettingsButton";

import logo from "images/onelifeArc/pageLogo.png";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    flexShrink: 0,
  },
  pageLogoContainer: {
    display: "flex",
    paddingTop: 20,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "row",
    height: 100,
    width: "100%",
  },
  pageLogo: {
    height: "100%",
    objectFit: "contain",
  },
  headerStripe: {
    flex: 1,
    width: "100%",
    height: "50%",
    borderBottom: `5px solid ${theme.onelifearc.green}`,
    marginLeft: 20,
  },
  single: {
    width: "90%",
    height: "90%",
    margin: "0 auto",

    "&>*": {
      width: "100%",
      height: "95%",
    },
  },
  noheader: {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "30px",
  },
  fullScreen: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
  inner: {
    height: "100%",
    zIndex: 10,

    "&>*:nth-child(1)": {
      width: "90%",
      height: "250px",
      margin: "10px auto",
      fontFamily: "monospace",
    },

    "&>*:nth-child(2)": {
      width: "90px",
      height: "90px",
      position: "absolute",
      bottom: "170px",
      left: "5%",
    },
    "&>*:nth-child(3)": {
      position: "absolute",
      width: "180px",
      height: "60px",
      bottom: "105px",
      left: "5%",
    },
    "&>*:nth-child(4)": {
      width: "90px",
      height: "90px",
      position: "absolute",
      bottom: "170px",
      left: "24%",
    },
    "&>*:nth-child(5)": {
      position: "absolute",
      width: "180px",
      height: "60px",
      bottom: "105px",
      left: "24%",
    },
    "&>*:nth-child(6)": {
      width: "90px",
      height: "90px",
      position: "absolute",
      bottom: "170px",
      left: "43%",
    },
    "&>*:nth-child(7)": {
      position: "absolute",
      width: "180px",
      height: "60px",
      bottom: "105px",
      left: "43%",
    },
    "&>*:nth-child(8)": {
      width: "90px",
      height: "90px",
      position: "absolute",
      bottom: "170px",
      left: "62%",
    },
    "&>*:nth-child(9)": {
      position: "absolute",
      width: "180px",
      height: "60px",
      bottom: "105px",
      left: "62%",
    },
    "&>*:nth-child(10)": {
      width: "90px",
      height: "90px",
      position: "absolute",
      bottom: "170px",
      left: "81%",
    },
    "&>*:nth-child(11)": {
      position: "absolute",
      width: "180px",
      height: "60px",
      bottom: "105px",
      left: "81%",
    },
  },
}));

interface PageProps {
  variant?: string;
}

const Page: UserComponent<PageProps> = ({ variant = "default" }) => {
  const classes = useStyles();
  const {
    connectors: { connect },
  } = useNode();

  let footer = <></>;
  let innerStyle = {};

  switch (variant) {
    case "withIcon":
      footer = (
        <>
          <div className={classes.pageLogoContainer}>
            <img
              src={logo}
              className={classes.pageLogo}
              alt="onc life arc logo"
            />
            <div className={classes.headerStripe}></div>
          </div>
          <div className={classes.inner} style={innerStyle}>
            <Element id="page-dropzone-title" is={Dropzone} canvas />

            <Element id="page-dropzone-image1" is={Dropzone} canvas />
            <Element id="page-dropzone-imageText1" is={Dropzone} canvas />

            <Element id="page-dropzone-image2" is={Dropzone} canvas />
            <Element id="page-dropzone-imageText2" is={Dropzone} canvas />

            <Element id="page-dropzone-image3" is={Dropzone} canvas />
            <Element id="page-dropzone-imageText3" is={Dropzone} canvas />

            <Element id="page-dropzone-image4" is={Dropzone} canvas />
            <Element id="page-dropzone-imageText4" is={Dropzone} canvas />

            <Element id="page-dropzone-image5" is={Dropzone} canvas />
            <Element id="page-dropzone-imageText5" is={Dropzone} canvas />
          </div>
        </>
      );
      break;
    case "noheader":
      footer = (
        <div className={classes.noheader}>
          <Element id="page-dropzone-noheader" is={Dropzone} canvas />
        </div>
      );
      break;

    case "fullScreen":
      footer = (
        <div className={classes.fullScreen}>
          <Element id="page-dropzone-fullscreen" is={Dropzone} canvas />
        </div>
      );
      break;
    default:
      footer = (
        <>
          <div className={classes.pageLogoContainer}>
            <img
              src={logo}
              className={classes.pageLogo}
              alt="onc life arc logo"
            />
            <div className={classes.headerStripe}></div>
          </div>
          <div className={classes.single}>
            <Element id="page-dropzone-signle" is={Dropzone} canvas />
          </div>
        </>
      );
      break;
  }

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
    >
      {footer}
    </div>
  );
};

const PageSettings = () => {
  const {
    variant,
    actions: { setProp },
  } = useNode(node => ({ variant: node.data.props.variant ?? "default" }));

  return (
    <>
      <SettingsButtonGroup label="Template" noMargin>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "default"))}
          active={variant === "default"}
        >
          Default
        </SettingsButton>

        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "withIcon"))}
          active={variant === "withIcon"}
        >
          With Icon
        </SettingsButton>
      </SettingsButtonGroup>
      <SettingsButtonGroup noMargin>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "fullScreen"))}
          active={variant === "fullScreen"}
        >
          Full Screen
        </SettingsButton>

        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "noheader"))}
          active={variant === "noheader"}
        >
          No header
        </SettingsButton>
      </SettingsButtonGroup>
    </>
  );
};

Page.craft = {
  related: {
    settings: PageSettings,
  },
};

export default Page;
