import { useNode } from "@craftjs/core";
import omit from "lodash/omit";

export const Text = ({ attributes, children, leaf }: any) => {
  const { id } = useNode();

  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.color) {
    children = <span style={{ color: leaf.color }}>{children}</span>;
  }

  children = (
    <span style={{ fontSize: `${leaf.size || 16}pt` }}>{children}</span>
  );

  if (leaf.url) {
    children = (
      <a
        href={leaf.url}
        style={{ color: "inherit" }}
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    );
  }

  return (
    <span data-craft-leaf-node={id} {...attributes}>
      {children}
    </span>
  );
};

Text.slate = {
  toCraftNode: (slateNode: any) => (craftNode: any) => {
    craftNode.data.custom.marks = omit(slateNode, ["id", "text"]);
    craftNode.data.props.text = slateNode.text;
  },
  toSlateNode: (craftNode: any) => (slateNode: any) => {
    slateNode.text = craftNode.data.props.text;
    if (craftNode.data.custom.marks) {
      for (const mark of Object.keys(craftNode.data.custom.marks)) {
        slateNode[mark] = craftNode.data.custom.marks[mark];
      }
    }
  },
};
