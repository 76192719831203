import { Editor as CraftJS } from "@craftjs/core";
import { SlateSetupProvider } from "@craftjs/slate";

import Heading from "components/editor/elements/Heading";
import Image from "components/editor/elements/Image";
import TwoCol from "components/editor/elements/TwoCol";
import NoteLocation from "components/editor/elements/NoteLocation";
import Quote from "components/editor/elements/Quote";
import RichText, {
  Typography,
  BulletedList,
  NumberedList,
  ListItem,
  Text,
} from "components/editor/elements/RichText";
import Dropzone from "components/editor/Dropzone";
import Template, {
  Cover,
  Page,
  Quotation,
  Chapter,
} from "components/editor/templates";

interface EditorProviderProps {
  enabled?: boolean;
}

const EditorProvider: React.FC<EditorProviderProps> = ({
  enabled = false,
  children,
}) => (
  <CraftJS
    enabled={enabled}
    resolver={{
      Image,
      TwoCol,
      Template,
      Cover,
      Page,
      Quotation,
      Chapter,
      Dropzone,
      NoteLocation,
      Heading,
      Quote,
      RichText,
      Typography,
      BulletedList,
      NumberedList,
      ListItem,
      Text,
    }}
  >
    <SlateSetupProvider
      editor={{ RichText }}
      elements={{ Typography, BulletedList, NumberedList, ListItem }}
      leaf={{ Text }}
    >
      {children}
    </SlateSetupProvider>
  </CraftJS>
);

export default EditorProvider;
