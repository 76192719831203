import { createUseStyles } from "react-jss";
import Theme, { Theme as TypeTheme } from "theme";
import { useStyleWithMarginProps, IMarginStyleProps } from "hooks";
import { Loading } from "../index";
interface IButtonSolidProps extends IMarginStyleProps {
  children?: any;
  onClick?: any;

  bgColor?: string;
  yellow?: boolean;
  full?: boolean;

  loading?: boolean;
}

const ButtonSolid = (props: IButtonSolidProps) => {
  const classes = useStyles();

  const computeStyle = (() => {
    const obj: any = {};
    obj.backgroundColor = (() => {
      if (props.bgColor) return props.bgColor;
      if (props.yellow) return Theme.yellow;
      return undefined;
    })();
    obj.width = (() => {
      if (props.full) return "100%";
      return undefined;
    })();
    return obj;
  })();
  const style = useStyleWithMarginProps(props, computeStyle);

  return (
    <button className={classes.btn} style={style} onClick={props.onClick}>
      {props.loading && (
        <div className={classes.loadingContainer}>
          <Loading size={10} white />
        </div>
      )}
      {props.children}
    </button>
  );
};

const useStyles = createUseStyles((theme: TypeTheme) => ({
  btn: {
    outline: "none",
    border: "none",
    backgroundColor: theme.background,
    color: theme.primary,
    padding: 10,
    cursor: "pointer",
    display: "block",
    textDecoration: "none",
  },
  loadingContainer: {
    display: "inline-block",
    marginRight: 10,
  },
}));

export default ButtonSolid;
