import { createUseStyles } from "react-jss";
import { Element, useNode, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";
import SettingsButton, {
  SettingsButtonGroup,
} from "../../settings/SettingsButton";

import redThing from "images/red-thing.png";
import logo from "images/logo.jpg";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    flexShrink: 0,
  },
  stripe: {
    width: "95%",
    height: 50,
    backgroundColor: theme.mcdonalds.yellow,
    position: "relative",
    borderBottomRightRadius: 50,
  },
  red: {
    height: "100%",
    position: "absolute",
    right: -50,
  },
  logo: {
    height: 50,
    position: "absolute",
    right: -50,
  },
  inner: {
    height: "calc(100% - 110px)",
    width: "calc(100% - 100px)",
    margin: [30, 50],
    display: "flex",
    flexDirection: "column",
    zIndex: 10,
  },
  borderTop: {
    position: "absolute",
    backgroundColor: theme.mcdonalds.yellow,
    height: 25,
    width: "100%",
  },
  borderLeft: {
    position: "absolute",
    backgroundColor: theme.mcdonalds.yellow,
    height: "100%",
    width: 25,
  },
  borderRight: {
    position: "absolute",
    backgroundColor: theme.mcdonalds.yellow,
    height: "100%",
    right: 0,
    width: 25,
  },
  borderBottom: {
    position: "absolute",
    backgroundColor: theme.mcdonalds.yellow,
    height: 25,
    width: "100%",
    bottom: 0,
  },
}));

interface PageProps {
  variant?: string;
}

const Page: UserComponent<PageProps> = ({ variant = "default" }) => {
  const classes = useStyles();
  const {
    connectors: { connect },
  } = useNode();

  let footer = <></>;
  let innerStyle = {};

  switch (variant) {
    case "red":
      footer = (
        <div className={classes.stripe} style={{ width: "90%" }}>
          <img src={redThing} alt="" className={classes.red} />
        </div>
      );
      break;
    case "logo":
      footer = (
        <div className={classes.stripe}>
          <img src={logo} alt="" className={classes.logo} />
        </div>
      );
      break;
    case "border":
      innerStyle = { margin: 50, height: "calc(100% - 100px)" };
      footer = (
        <>
          <div className={classes.borderTop} />
          <div className={classes.borderLeft} />
          <div className={classes.borderRight} />
          <div className={classes.borderBottom} />
        </>
      );
      break;
    case "border-red":
      innerStyle = { margin: 50, height: "calc(100% - 100px)" };
      footer = (
        <>
          <div className={classes.borderTop} />
          <div className={classes.borderLeft} />
          <div className={classes.borderRight} />
          <div className={classes.stripe} style={{ height: 25, width: "90%" }}>
            <img
              src={redThing}
              alt=""
              className={classes.red}
              style={{ right: -25 }}
            />
          </div>
        </>
      );
      break;
    default:
      footer = <div className={classes.stripe} />;
      break;
  }

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
    >
      <div className={classes.inner} style={innerStyle}>
        <Element id="page-dropzone" is={Dropzone} canvas />
      </div>
      {footer}
    </div>
  );
};

const PageSettings = () => {
  const {
    variant,
    actions: { setProp },
  } = useNode(node => ({ variant: node.data.props.variant ?? "default" }));

  return (
    <>
      <SettingsButtonGroup label="Template" noMargin>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "default"))}
          active={variant === "default"}
        >
          Default
        </SettingsButton>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "red"))}
          active={variant === "red"}
        >
          Red
        </SettingsButton>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "logo"))}
          active={variant === "logo"}
        >
          Logo
        </SettingsButton>
      </SettingsButtonGroup>
      <SettingsButtonGroup>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "border"))}
          active={variant === "border"}
        >
          Border
        </SettingsButton>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "border-red"))}
          active={variant === "border-red"}
        >
          Border (red)
        </SettingsButton>
      </SettingsButtonGroup>
    </>
  );
};

Page.craft = {
  related: {
    settings: PageSettings,
  },
};

export default Page;
