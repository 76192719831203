import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { createUseStyles } from "react-jss";
import shallow from "zustand/shallow";

import { usePlaybookStore } from "hooks";
import { Loading } from "components";
import Scene from "components/editor/ScenePrint";
import EditorProvider from "./editor/EditorProvider";
import { Theme } from "theme";
import "./PdfView.css";

const PRINT_PAGE_SIZE_IN_PIXEL = {
  width: 1131,
  height: 640,
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    "@media print": {
      width: 1131,
      height: 640,
    },
  },
  title: {
    backgroundColor: "#333",
    color: "#fff",
    padding: [10, 10],
    fontSize: 24,
  },
  editor: {
    ...PRINT_PAGE_SIZE_IN_PIXEL,
    // pageBreakAfter: 'always',
    margin: 0,
    overflow: "hidden",
    "&:last-of-type": {
      marginBottom: -2,
    },
  },
  centre: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    overflow: "auto",
  },
  centered: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: 3,
    padding: 10,
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",

    "& h1": {
      textAlign: "center",
    },
  },
  pageTypes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: [15, 0],
  },
  pageType: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",

    "& img": {
      width: 100,
      height: "auto",
      borderRadius: 3,
      border: "3px solid transparent",
    },

    "& p": {
      textAlign: "center",
      margin: [5, 0],
    },
  },
  active: {
    "& img": {
      borderColor: theme.mcdonalds.yellow,
    },

    "& p": {
      fontWeight: "bold",
    },
  },
  label: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    fontWeight: "bold",
  },
  input: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    marginTop: 5,
    height: 40,
    outline: "none",
    padding: "5px 10px",
    fontSize: 16,
  },
}));

const Viewer = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const { token } = useParams<{ token: string }>();
  const [playbook, getDetail] = usePlaybookStore(
    state => [state.playbook, state.getDetail],
    shallow,
  );

  useEffect(() => {
    setTimeout(() => {
      const navEl: HTMLElement = document.querySelector("#root > div > nav");
      if (navEl) {
        navEl.style.display = "none";
      }
    }, 300);
  }, []);

  useEffect(() => {
    (async () => {
      const id = (() => {
        try {
          const splitID = token.split("-")[0];
          return +splitID || 0;
        } catch (err) {
          return 0;
        }
      })();
      const [, err] = await getDetail(id);
      if (err) {
        console.error(err);
        setError(true);
      }
      setLoading(false);
    })();
  }, [token, getDetail]);

  const pages = playbook?.content.pages ?? [];

  if (loading)
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );

  if (error)
    return (
      <div className={classes.centered}>
        <p>An error occured</p>
      </div>
    );

  if (playbook === null)
    return <div className={classes.centered}>No playbook found</div>;

  return (
    <div className={classes.container}>
      {pages.map((page, i) => (
        <div className={classes.editor} key={page.id}>
          <EditorProvider>
            <div className={classes.centre}>
              <Scene pageNumber={i + 1} activePage={page} onChange={() => {}} />
            </div>
          </EditorProvider>
        </div>
      ))}
    </div>
  );
};

export default Viewer;
