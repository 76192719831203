import theme from "theme";
import { EditorAssetsType } from "./EditorPageType";

import coverThumbnail from "images/onelifeArc/cover.png";
import pageThumbnail from "images/onelifeArc/page-thumbnail.png";
import NoteIcon from "images/onelifeArc/noteIcon.svg";

export const onelifearcEditorAssets: EditorAssetsType = {
  linkColor: theme.onelifearc.cyan,
  selectedColor: theme.onelifearc.green,
  pages: [
    {
      pageType: "cover",
      text: "Cover Page",
      imageSrc: coverThumbnail,
      imageAlt: "cover page",
    },
    {
      pageType: "page",
      text: "Normal Page",
      imageSrc: pageThumbnail,
      imageAlt: "normal page",
    },
    {
      pageType: "chapter",
      text: "Chapter Page",
      imageSrc: pageThumbnail,
      imageAlt: "chapter page",
    },
  ],
  fontColor: {
    black: "black",
    white: "white",
    green: "#00cf48",
    green2: "#004C23",
    green3: "#d1e2a8",
    cyan: "#004a4f",
    cyan2: "#4fac99",
    cyan3: "#a6d1d7",
    purple: "#411d64",
    purple1: "#f5f1fa",
    purple2: "#a42efc",
    purple3: "#c8b4d6",
    yellow: "#f0b02f",
    yellow1: "#f3eb72",
    yellow2: "#f8d962",
    yellow3: "#f9e590",
    pink: "#681240",
    pink1: "#d95196",
    pink2: "#eccadf",
    lightCyan: "#86d1ce",
  },
  myNotesBoxBackgroundColor: theme.onelifearc.green,
  myNotesBoxFontColor: theme.onelifearc.white,
  playbookAltText: "Toolkit",

  // Quote Mark
  quoteMarkColor: theme.onelifearc.lightCyan,
  quoteTextColor: theme.onelifearc.black,
  noteboxColor: theme.onelifearc.green,
  noteboxContentBackgroundColor: "#FFF",
  noteboxBinColor: "#E2388C",
  noteBoxIcon: NoteIcon,
};
