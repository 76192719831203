import { useEffect, useState } from "react";
import shallow from "zustand/shallow";
import { createUseStyles } from "react-jss";
import { Helmet } from "react-helmet-async";

import { usePlaybookStore, useStore } from "hooks";
import { TPageProps } from "type";
import { Button, NavRight, PlaybookItem } from "components";
import { Theme as TypeTheme } from "theme";
import EditorTheme from "../themes";

const Homepage = ({ history }: TPageProps) => {
  const user = useStore(state => state.user);
  const [listPlaybook, getListPlaybook] = usePlaybookStore(
    state => [state.listPlaybook, state.getListPlaybook],
    shallow,
  );
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [moreData, setMoreData] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    (async () => {
      setLoading(true);
      const data = await getListPlaybook(page);
      if (data[0].length === 0) {
        setMoreData(false);
      }
      setLoading(false);
    })();
  }, [getListPlaybook, page]);

  const goToCreateNew = () => {
    history.push("/editor/new");
  };

  return (
    <div>
      <Helmet>
        <title>{EditorTheme.playbookAltText}</title>
      </Helmet>
      {user.role === "admin" && (
        <NavRight>
          <Button.Solid bgColor={EditorTheme.linkColor} onClick={goToCreateNew}>
            Add a new {EditorTheme.playbookAltText}
          </Button.Solid>
        </NavRight>
      )}
      <ul className={classes.listContainer}>
        {listPlaybook.map((val, i) => (
          <div key={val.ID} className={classes.itemContainer}>
            <PlaybookItem data={val} />
          </div>
        ))}
      </ul>
      {moreData && (
        <div className={classes.center}>
          {loading ? (
            <Button.Outline>Loading...</Button.Outline>
          ) : (
            <Button.Outline onClick={() => setPage(p => p + 1)}>
              Load More (page {page})
            </Button.Outline>
          )}
        </div>
      )}
    </div>
  );
};

const useStyles = createUseStyles((theme: TypeTheme) => ({
  btn: {
    outline: "none",
    border: "none",
    backgroundColor: theme.background,
    color: theme.primary,
    padding: 10,
    cursor: "pointer",
  },
  listContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    padding: 20,
    marginTop: 20,
    width: "70%",
    marginLeft: "15%",
    "@media screen and (max-width: 768px)": {
      width: "100%",
      marginLeft: "0%",
    },
  },
  itemContainer: {
    marginBottom: 20,
    width: "33%",
    boxSizing: "border-box",
    "@media screen and (max-width: 480px)": {
      width: "50%",
    },
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
}));

export default Homepage;
