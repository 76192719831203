import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { createUseStyles } from "react-jss";
import ContentEditable from "react-contenteditable";
import { MdDelete as DeleteIcon } from "react-icons/md";
import { BiImageAdd as AddImageIcon } from "react-icons/bi";
import produce from "immer";

import { Theme } from "theme";
import { TNotesTool } from "type";
import { useHover, useWindowSize } from "hooks";
import EditorTheme from "themes";
import FileInput from "components/fileInput/FileInput";
import openBase64InNewTab from "utils/openBase64InNewTab";

const UPLOADED_IMAGE_WIDTH = 180;

interface NoteProps {
  note: TNotesTool;
  update: (partial: Partial<TNotesTool>) => void;
  deleteNote: () => void;
  scale: number;
}

const Note: React.FC<NoteProps> = ({ note, update, scale, deleteNote }) => {
  const [active, setActive] = useState(false);
  const classes = useStyles({ active });
  const [pos, setPos] = useState(note.position);
  const [ref, hovering] = useHover();
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const editableRef = useRef(null);

  useEffect(() => {
    if (hovering) {
      setActive(true);
    } else {
      timeoutRef.current = setTimeout(() => setActive(false), 1000);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [hovering]);

  const onClickContentEditable = e => {
    if (e.target === editableRef.current) {
      return;
    }

    if (e.target.tagName === "IMG") {
      openBase64InNewTab(e.target.src);
      // console.log(e.target.src)
    }
    // an element in `ContentEditable` was clicked!
    // if (e.target.className.indexOf("click-me") > -1) {
    //   console.log("You clicked the winner!");
    // }
    // console.log(
    //   "you clicked: ",
    //   e.target,
    //   " which is a ",
    //   e.target.tagName,
    //   "tag"
    // );
  };

  return (
    <Draggable
      position={pos}
      scale={scale}
      onDrag={(_, newPos) => {
        setPos({ x: newPos.x, y: newPos.y });
      }}
      onStop={(_, newPos) => {
        setPos({ x: newPos.x, y: newPos.y });
        update({ position: { x: newPos.x, y: newPos.y } });
      }}
    >
      <div className={classes.note} ref={ref}>
        <img
          src={EditorTheme.noteBoxIcon}
          alt="Note"
          className={classes.handle}
          onDragStart={evt => evt.preventDefault()}
        />

        <div className={classes.noteBox}>
          <div className={classes.noteHeader}>
            <ContentEditable
              tagName="p"
              html={note.title}
              className={classes.noteTitle}
              onChange={evt => update({ title: evt.target.value })}
            />

            <div className={classes.imageUpload}>
              <label htmlFor={`fileInput_${note.id}`}>
                <AddImageIcon
                  fill={EditorTheme.quoteTextColor}
                  className={classes.addImageIcon}
                />
              </label>
              <FileInput
                onChange={item => {
                  console.log("note", note);
                  return update({
                    content:
                      note.content +
                      `<div style="cursor:pointer;"><img width="${UPLOADED_IMAGE_WIDTH}" src='${item}' /></div><br />`,
                  });
                }}
                id={`fileInput_${note.id}`}
                accept="image/*"
              />
            </div>

            <DeleteIcon
              fill={EditorTheme.noteboxBinColor}
              className={classes.trash}
              onClick={deleteNote}
            />
          </div>
          <div className={classes.noteContent}>
            <ContentEditable
              innerRef={editableRef}
              onClick={onClickContentEditable}
              tagName="div"
              html={note.content}
              onChange={evt => {
                update({ content: evt.target.value });
              }}
            />
          </div>
        </div>
      </div>
    </Draggable>
  );
};

interface NotesProps {
  data: TNotesTool[];
  update: (notes: TNotesTool[]) => void;
  padding: number;
}

const Notes: React.FC<NotesProps> = ({ data, update, padding }) => {
  const { width } = useWindowSize();

  return (
    <>
      {data.map((note, index) => (
        <Note
          key={note.id}
          note={note}
          scale={(width - padding) / 1131}
          deleteNote={() =>
            update(
              produce(data, draft => {
                draft.splice(index, 1);
              }),
            )
          }
          update={(partial: Partial<TNotesTool>) => {
            update(
              produce(data, draft => {
                draft[index] = { ...note, ...partial };
              }),
            );
          }}
        />
      ))}
    </>
  );
};

type StyleProps = {
  active: boolean;
};

const useStyles = createUseStyles((theme: Theme) => ({
  note: {
    position: "absolute",
    zIndex: (props: StyleProps) => (props.active ? 10001 : 10000),
    pointerEvents: "auto",
    display: "flex",
    alignItems: "flex-start",
    cursor: "auto",
  },
  handle: {
    width: 30,
    cursor: "grab",
    transition: "opacity 0.1s ease",
    opacity: (props: StyleProps) => (props.active ? 1 : 0.6),
  },
  noteBox: {
    marginLeft: 10,
    width: 200,
    backgroundColor: EditorTheme.noteboxColor,
    transition: "opacity 0.2s ease",
    opacity: (props: StyleProps) => (props.active ? 1 : 0),
    pointerEvents: (props: StyleProps) => (props.active ? "auto" : "none"),
    padding: 2,
    borderRadius: 5,
    fontFamily: "Speedee, Arial, sans-serif",
  },
  noteTitle: {
    fontWeight: "bold",
    fontSize: 16,
    margin: [0, 5],
    padding: [3, 0, 0],
    flex: 1,
  },
  noteContent: {
    fontSize: 12,
    backgroundColor: EditorTheme.noteboxContentBackgroundColor,
    padding: 5,
    borderRadius: [0, 0, 5, 5],
    "& p": { margin: 0 },
  },
  noteHeader: {
    display: "flex",
    width: "100%",
    alignItems: "center",
  },
  trash: {
    width: 20,
    flexGrow: 0,
    cursor: "pointer",
  },
  addImageIcon: {
    width: 20,
    flexGrow: 0,
    cursor: "pointer",
  },
  fileInput: {
    display: "none",
  },
  imageUpload: {
    "&>label": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export default Notes;
