import { createUseStyles } from "react-jss";
import { useNode, UserComponent } from "@craftjs/core";
import ContentEditable from "react-contenteditable";

import { Theme } from "theme";
import { useEffect, useState } from "react";
import DragBox from "../DragBox";
import EditorTheme from "themes";

interface QuoteProps {
  quote: string;
  author: string;
}

const Quote: UserComponent<QuoteProps> = ({ quote, author }) => {
  const {
    connectors: { connect },
    actions: { setProp },
    selected,
  } = useNode(state => ({ selected: state.events.selected }));
  const [editable, setEditable] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (!selected) setEditable(false);
  }, [selected]);

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
      onClick={() => selected && setEditable(true)}
    >
      <DragBox show={selected} />
      <p>
        <ContentEditable
          html={quote}
          disabled={!editable}
          onChange={evt => {
            const text = evt.target.value
              .replace(/<div>/gi, "<br>")
              .replace(/<\/div>/gi, "");
            setProp(props => (props.quote = text));
          }}
          tagName="q"
          className={classes.quote}
        />
        <ContentEditable
          html={author}
          disabled={!editable}
          onChange={evt => {
            const text = evt.target.value
              .replace(/<div>/gi, "<br>")
              .replace(/<\/div>/gi, "");
            setProp(props => (props.author = text));
          }}
          tagName="span"
          className={classes.author}
        />
      </p>
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    maxWidth: "45%",
    margin: "auto",
    color: EditorTheme.quoteTextColor,
    fontFamily: "Speedee, Arial, sans-serif",
    fontWeight: "bolder",
    position: "relative",

    "& p": {
      margin: 0,
    },
  },
  quote: {
    fontSize: "32pt",
    position: "relative",

    "&::before": {
      content: "open-quote",
      color: EditorTheme.quoteMarkColor,
      position: "absolute",
      left: -17,
      top: -5,
    },

    "&::after": {
      content: "close-quote",
      color: EditorTheme.quoteMarkColor,
    },
  },
  author: {
    fontSize: "24pt",
    position: "relative",
    marginLeft: 50,

    "&::before": {
      content: "' by '",
      position: "absolute",
      left: -45,
      top: -4,
    },
  },
}));

export default Quote;
