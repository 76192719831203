export type Theme = {
  background: string;
  branchColor: string;
  primary: string;
  secondary: string;
  yellow: string;
  mcdonalds: {
    yellow: string;
    red: string;
  };
  onelifearc: {
    green: string;
    blue: string;
    cyan: string;
  };
};

export default {
  background: "var(--background-color)", // check initial value in index.css
  branchColor: "var(--branch-color)",
  primary: "white",
  secondary: "#f5f5f5",
  yellow: "#F3BE43",
  mcdonalds: {
    black: "#000000",
    yellow: "#ffbc0d",
    red: "#c8161d",
  },
  onelifearc: {
    black: "black",
    white: "white",
    green: "#00cf48",
    cyan: "#004a4f",
    lightCyan: "#86d1ce",
  },
} as const;
