import { useMemo, useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router-dom";
import { Theme } from "theme";
import { CONFIG } from "utils";

import NavLeftUser from "./NavLeftUser";

interface INavControl {
  setRightComponent(comp: any): void;
}

export const navControl: INavControl = {
  setRightComponent: (comp = null) => {},
};

export const NavRight: React.FC = ({ children }) => {
  useEffect(() => {
    navControl.setRightComponent(children);
    return () => {
      navControl.setRightComponent(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
};

const Nav: React.FC = ({ children }) => {
  const [rightComponent, setRightComponent] = useState(null);
  const classes = useStyles();
  navControl.setRightComponent = setRightComponent;
  const history = useHistory();
  const goHome = () => {
    history.push("/");
  };
  return (
    <div className={classes.container}>
      <nav className={classes.nav}>
        <NavLeftUser />
        {rightComponent}
        <div className={classes.brandName} onClick={goHome}>
          {Boolean(CONFIG.LOGO) && (
            <img
              src={CONFIG.LOGO}
              className={classes.brandImage}
              alt="brand logo"
            />
          )}
          {CONFIG.NAME}
        </div>
      </nav>
      {/* eslint-disable-next-line react-hooks/exhaustive-deps */}
      {useMemo(() => children, [])}
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    width: "100%",
    minHeight: "100vh",
  },
  nav: {
    height: 70,
    background: theme.background,
    position: "relative",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: [8, 16],
    justifyContent: "space-between",
    "& svg": {
      cursor: "pointer",
    },
  },
  brandName: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "white",
    fontWeight: "bold",
    fontSize: 30,
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 480px)": {
      display: "none",
    },
  },
  brandImage: {
    height: 40,
    width: "auto",
    marginRight: 10,
  },
}));

export default Nav;
