import swal from "sweetalert";

type IsError = boolean;

const Dialog = {
  confirmDelete: async (text: string = "") => {
    const res = await swal({
      title: "Are you sure?",
      text: text || "You will not be able to recover this!",
      icon: "warning",
      // @ts-ignore
      buttons: true,
      dangerMode: true,
    });
    return Boolean(res);
  },
  checkAndShowError: (res, err): IsError => {
    if (err) {
      swal("Error!", String(err), "error");
      return true;
    }
    if (!!res && res.error) {
      swal("Error!", String(res.error), "error");
      return true;
    }
    return false;
  },
};

export default Dialog;
