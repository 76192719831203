import { createUseStyles } from "react-jss";
import { Element, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";

import chapterBackground from "images/chapter-background.jpg";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flexShrink: 0,
  },
  inner: {
    position: "absolute",
    left: 80,
    top: 80,
    height: 470,
    width: 415,
    zIndex: 10,
    display: "flex",

    "& > *": {
      justifyContent: "center",
    },
  },
  arches: {
    position: "absolute",
    left: 0,
    top: 0,
    height: "100%",
    width: "100%",
    objectFit: "cover",
  },
}));

const Chapter: UserComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <Element id="chapter-dropzone" is={Dropzone} canvas />
      </div>
      <img
        src={chapterBackground}
        className={classes.arches}
        alt="Golden Arches"
      />
    </div>
  );
};

export default Chapter;
