import create from "zustand";
import { fetchApi, errorInArray } from "utils";
import {
  TPlaybook,
  TPlaybookComment,
  TPlaybookAnnotation,
  TErrorInArray,
} from "type";

interface State {
  listPlaybook: TPlaybook[];
  getListPlaybook: (page: number) => TErrorInArray<TPlaybook[]>;
  createPlaybook: (data: TPlaybook) => TErrorInArray<TPlaybook>;
  updatePlaybook: (data: TPlaybook) => TErrorInArray<TPlaybook>;
  deletePlaybook: (id: string) => TErrorInArray<any>;
  getDetail: (id: number) => TErrorInArray<{
    detail: TPlaybook;
    comments: TPlaybookComment[];
    annotations: TPlaybookAnnotation[];
  }>;
  page: number;
  setPage: (page: number) => void;
  playbook: TPlaybook;
  comments: Array<TPlaybookComment>;
  annotation: TPlaybookAnnotation;
  annotations: TPlaybookAnnotation[];
  createComment: (
    data: Pick<TPlaybookComment, "playbookID" | "pageID" | "content">,
  ) => TErrorInArray<TPlaybookComment>;
  updateComment: (
    data: Pick<TPlaybookComment, "ID" | "content">,
  ) => TErrorInArray<TPlaybookComment>;
  createAnnotation: () => TErrorInArray<TPlaybookAnnotation>;
  updateAnnotation: (
    data: TPlaybookAnnotation["lines"],
  ) => TErrorInArray<TPlaybookAnnotation>;
  clearAnnotation: () => TErrorInArray<any>;
  exportPDF: (id: string) => TErrorInArray<{ url: string }>;
}

const usePlaybookStore = create<State>((set, get) => ({
  listPlaybook: [],
  playbook: null,
  comments: [],
  annotation: null,
  annotations: [],
  comment: null,
  page: 0,
  setPage: (page: number) => {
    set({
      page,
      annotation: get().annotations.find(
        a => a.pageID === get().playbook.content.pages[page].id,
      ),
    });
  },
  getListPlaybook: (p = 1) => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks?page=${p}`);
      if (res.success) {
        if (res.data !== null) {
          if (p === 1) {
            set({ listPlaybook: res.data });
          } else {
            set({ listPlaybook: [...get().listPlaybook, ...res.data] });
          }
        }
        return res.data;
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  createPlaybook: data => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks`, "post", data);
      if (res.success) {
        set(state => {
          const newList = state.listPlaybook.slice();
          newList.unshift(res.data);
          return { listPlaybook: newList };
        });
        return res.data;
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  updatePlaybook: data => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks`, "put", data);
      if (res.success) {
        set(state => {
          const newList = state.listPlaybook.slice();
          const findIndex = newList.findIndex(val => val.ID === res.data.ID);
          if (findIndex !== -1) newList[findIndex] = res.data;
          return { listPlaybook: newList };
        });
        return res.data;
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  deletePlaybook: id => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks?id=${id}`, "delete");
      if (res.success) {
        set(state => {
          const newList = state.listPlaybook.filter(val => val.ID !== id);
          return { listPlaybook: newList };
        });
        return res.data;
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  getDetail: id => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/${id}`);
      if (res.success) {
        const data: {
          detail: TPlaybook;
          comments: TPlaybookComment[];
          annotations: TPlaybookAnnotation[];
        } = res.data;
        set({
          playbook: data.detail,
          comments: data.comments,
          annotations: data.annotations,
          annotation: data.annotations.find(
            ann => ann.pageID === data.detail.content.pages[get().page].id,
          ),
        });
        return res.data;
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  createComment: data => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/comments`, "post", data);
      if (res.success) {
        // set(state => ({
        //   playbook: {
        //     ...state.playbook,
        //     comments: state.playbook.comments.map(x =>
        //       x.ID === res.data.ID ? res.data : x,
        //     ),
        //   },
        // }));
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  updateComment: data => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/comments`, "put", data);
      if (res.success) {
        // set(state => ({
        //   playbook: {
        //     ...state.playbook,
        //     comments: state.playbook.comments.map(x =>
        //       x.ID === res.data.ID ? res.data : x,
        //     ),
        //   },
        // }));
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  createAnnotation: () => {
    const data = {
      playbookID: get().playbook.ID,
      pageID: get().playbook.content.pages[get().page].id,
      lines: [],
    };

    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/annotations`, "post", data);
      if (res.success) {
        set({ annotation: res.data });
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  updateAnnotation: lines => {
    const data = {
      ...get().annotation,
      lines,
    };
    set({
      annotation: data,
      annotations: get().annotations.map(x => (x.ID === data.ID ? data : x)),
    });
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/annotations`, "put", data);
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },
  clearAnnotation: () => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/annotations`, "put", {
        ...get().annotation,
        lines: [],
      });
      if (res.success) {
        set({ annotation: res.data });
      }
      if (res.error) {
        throw new Error(res.error);
      }
    });
  },

  exportPDF: (ID: string) => {
    return errorInArray(async () => {
      const res = await fetchApi(`/api/playbooks/export-pdf`, "post", { ID });
      if (res.error) {
        throw new Error(res.error);
      }
      if (res.success) return res.data;
    });
  },
}));

export default usePlaybookStore;
