import { KonvaEventObject } from "konva/lib/Node";
import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Layer, Stage, Line, Arrow } from "react-konva";

import { Theme } from "theme";
import { TArrowTool, TEraserTool, TPenTool } from "type";

interface ScribbleProps {
  scribble: boolean;
  arrow: boolean;
  eraser: boolean;
  data: (TPenTool | TArrowTool | TEraserTool)[];
  colour: string;
  onDone: () => void;
  clear: boolean;
  undo: boolean;
  update: (data: (TPenTool | TArrowTool | TEraserTool)[]) => void;
}

const Scribble: React.FC<ScribbleProps> = ({
  scribble,
  arrow,
  eraser,
  data,
  colour,
  onDone,
  clear,
  undo,
  update,
}) => {
  const [lines, setLines] = useState(data);
  const [firstClick, setFirstClick] = useState<{ x: number; y: number }>(null);
  const [pos, setPos] = useState<{ x: number; y: number }>(null);
  const classes = useStyles();
  const isDrawing = useRef(false);

  useEffect(() => {
    if (clear) {
      setLines([]);
      update([]);
    }
  }, [clear, update]);

  useEffect(() => {
    setLines(data);
  }, [data]);

  useEffect(() => {
    setPos(null);
  }, [eraser]);

  useEffect(() => {
    if (undo) {
      update(lines.slice(0, -1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [undo, update]);

  const handleMouseDown = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
    evt.evt.preventDefault();
    const pos = evt.target.getStage().getPointerPosition();
    setPos(pos);
    if (scribble || eraser) {
      isDrawing.current = true;
      setLines(lines => [
        ...lines,
        { tool: scribble ? "pen" : "eraser", colour, points: [pos.x, pos.y] },
      ]);
    } else if (arrow) {
      if (firstClick) {
        let newLines = [
          ...lines,
          {
            tool: "arrow",
            colour,
            points: [firstClick.x, firstClick.y, pos.x, pos.y],
          },
        ] as React.SetStateAction<(TPenTool | TArrowTool | TEraserTool)[]>;

        setLines(newLines);
        setFirstClick(null);
        onDone();
        setTimeout(() =>
          update(newLines as (TPenTool | TArrowTool | TEraserTool)[]),
        );
      } else {
        setFirstClick({ x: pos.x, y: pos.y });
        setPos(null);
      }
    }
  };

  const handleMouseMove = (evt: KonvaEventObject<MouseEvent | TouchEvent>) => {
    evt.evt.preventDefault();
    const pos = evt.target.getStage().getPointerPosition();
    if (eraser || arrow) setPos(pos);
    // no drawing - skipping
    if (!isDrawing.current) {
      return;
    }
    let lastLine = lines[lines.length - 1];
    // add point
    lastLine.points = lastLine.points.concat([pos.x, pos.y]);

    // replace last
    lines.splice(lines.length - 1, 1, lastLine);
    setLines(lines.concat());
  };

  const handleMouseUp = () => {
    isDrawing.current = false;
    setPos(null);
    setTimeout(() => update(lines));
  };

  return (
    <>
      <Stage
        width={1131}
        height={640}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onTouchStart={handleMouseDown}
        onTouchMove={handleMouseMove}
        onTouchEnd={handleMouseUp}
      >
        <Layer>
          {(arrow && firstClick && pos
            ? [
                ...lines,
                {
                  tool: "arrow",
                  colour,
                  points: [firstClick.x, firstClick.y, pos.x, pos.y],
                },
              ]
            : lines
          ).map((line, index) =>
            line.tool === "arrow" ? (
              <Arrow
                key={index}
                points={line.points}
                stroke={line.colour}
                fill={line.colour}
                strokeWidth={5}
              />
            ) : (
              <Line
                key={index}
                points={line.points}
                tension={0.5}
                lineCap="round"
                globalCompositeOperation={
                  line.tool === "eraser" ? "destination-out" : "source-over"
                }
                stroke={line.colour}
                strokeWidth={line.tool === "pen" ? 8 : 15}
                opacity={line.tool === "pen" ? 0.5 : 1}
              />
            ),
          )}
        </Layer>
      </Stage>
      {pos && eraser && (
        <div
          className={classes.cursor}
          style={{
            top: pos.y - 11,
            left: pos.x - 11,
          }}
        />
      )}
      {firstClick && (
        <div
          className={classes.cursor}
          style={{
            top: firstClick.y - 11,
            left: firstClick.x - 11,
          }}
        />
      )}
    </>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  cursor: {
    position: "absolute",
    borderRadius: 10,
    border: "1px solid black",
    width: 20,
    height: 20,
    pointerEvents: "none",
  },
}));

export default Scribble;
