import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import shallow from "zustand/shallow";
import {
  MdPictureAsPdf as PDFIcon,
  MdFullscreen as FullscreenIcon,
  MdFullscreenExit as UnfullscreenIcon,
} from "react-icons/md";
import { TwitterPicker } from "react-color";
import { Helmet } from "react-helmet-async";

import ViewerPagesPanel from "components/editor/ui/ViewerPagesPanel";
import ViewerPagesFooterPanel from "components/editor/ui/ViewerPagesFooterPanel";
import MobilePinchZoomContainer from "components/editor/ui/MobilePinchZoomContainer";
import { usePlaybookStore, useUIState, useWindowSize } from "hooks";
import { Loading, NavRight, Button, Dialog } from "components";
import Scene from "components/editor/Scene";
import useDeepMemo from "hooks/useDeepMemo";
import Annotations from "components/editor/annotations/Annotations";
import { Theme } from "theme";
import useMatchMedia from "use-match-media-hook";
import EditorProvider from "./editor/EditorProvider";
import EditorTheme from "../themes";

const mediaQueries = ["(max-width: 480px)", "(min-width: 768px)"];

const Viewer = () => {
  const history = useHistory();
  const [isMobile, isDesktop] = useMatchMedia(mediaQueries);
  const [loading, setLoading] = useState<boolean>(true);
  const [playbook, getDetail, setPage, exportPDF, clearAnnotation] =
    usePlaybookStore(
      state => [
        state.playbook,
        state.getDetail,
        state.setPage,
        state.exportPDF,
        state.clearAnnotation,
      ],
      shallow,
    );
  const { token } = useParams<{ token: string }>();
  const [activePage, setActivePage] = useState<number>(0);
  const [{ loading: loadingExportPDF }, setUI] = useUIState();
  const [annotationState, setAnnotationState] = useState<
    "scribble" | "arrow" | "remove" | "new-note" | null
  >(null);
  const [showColourPicker, setShowColourPicker] = useState<boolean>(false);
  const [colour, setColour] = useState<string>("#FCB900");
  const [clear, setClear] = useState<boolean>(false);
  const [undo, setUndo] = useState<boolean>(false);
  const [note, setNote] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [url, setUrl] = useState<string>(null);
  const [fullscreen, setFullscreen] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const classes = useStyles({ fullscreen, ...windowSize });

  useEffect(() => {
    setFullscreen(true);
    document.body.style.overflowY = "hidden";
  }, [isMobile]);

  useEffect(() => {
    (async () => {
      const id = (() => {
        try {
          const splitID = token.split("-")[0];
          return +splitID || 0;
        } catch (err) {
          return 0;
        }
      })();
      const [, err] = await getDetail(id);
      if (err) {
        console.error(err);
        setError(true);
      }
      setLoading(false);
    })();
  }, [token, getDetail]);

  useEffect(() => {
    setPage(activePage);
  }, [activePage, setPage]);

  const pages = playbook?.content.pages ?? [];

  const currentPage = useDeepMemo(() => pages[activePage], [pages, activePage]);

  const backToList = () => {
    history.push("/");
  };

  if (error)
    return (
      <div className={classes.centered}>
        <p>An error occured</p>
        <Button.Outline onClick={backToList}>Back</Button.Outline>
      </div>
    );

  if (loading)
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );

  if (!playbook)
    return <div className={classes.centered}>No playbook found</div>;

  const handleExportPDF = async () => {
    if (!playbook) return;
    setUI({ loading: true });
    const [res, err] = await exportPDF(playbook.ID);
    setUI({ loading: false });
    if (Dialog.checkAndShowError(res, err)) return;
    setUrl(`https:${res.url}`);
  };

  const mobilePinchZoomLocked =
    annotationState === "scribble" ||
    annotationState === "arrow" ||
    annotationState === "remove" ||
    annotationState === "new-note";

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Playbooks | {playbook.name}</title>
      </Helmet>
      <NavRight>
        <Button.Solid bgColor={EditorTheme.linkColor} onClick={backToList}>
          Back to list
        </Button.Solid>
      </NavRight>
      <div className={classes.title}>
        {playbook.name}
        {url ? (
          <a href={url} target="_blank" rel="noopener noreferrer">
            <PDFIcon color="white" fontSize={20} />
            <span>Click to Download</span>
          </a>
        ) : (
          <aside onClick={handleExportPDF}>
            {loadingExportPDF ? (
              <Loading white size={20} />
            ) : (
              <PDFIcon color="white" fontSize={20} />
            )}
            <span>Export PDF</span>
          </aside>
        )}
      </div>

      <div className={classes.viewer}>
        <EditorProvider>
          {isDesktop && (
            <ViewerPagesPanel
              pages={pages}
              activePage={activePage}
              setActivePage={setActivePage}
              show={!fullscreen}
            />
          )}
          {isMobile ? null : fullscreen ? (
            <UnfullscreenIcon
              className={classes.fullscreen}
              onClick={() => setFullscreen(false)}
            />
          ) : (
            <FullscreenIcon
              className={classes.fullscreen}
              onClick={() => setFullscreen(true)}
            />
          )}
          <div className={classes.centre}>
            <div className={classes.annotations}>
              <div className={classes.annotationBar}>
                {/* <Button.Outline
                  onClick={() => setZoom(z => (z < 10 ? z + 1 : 0))}
                >
                  Zoom In
                </Button.Outline>
                <Button.Outline
                  onClick={() => setZoom(z => (z > 0 ? z - 1 : 0))}
                >
                  Zoom Out
                </Button.Outline> */}
                <Button.Outline
                  onClick={
                    annotationState === "scribble"
                      ? () => setAnnotationState(null)
                      : () => setAnnotationState("scribble")
                  }
                >
                  {annotationState === "scribble"
                    ? "Stop Scribbling"
                    : "Scribble"}
                </Button.Outline>
                <Button.Outline
                  onClick={
                    annotationState === "arrow"
                      ? () => setAnnotationState(null)
                      : () => setAnnotationState("arrow")
                  }
                >
                  {annotationState === "arrow" ? "Cancel" : "Arrow"}
                </Button.Outline>
                <Button.Outline
                  onClick={() =>
                    annotationState === "remove"
                      ? setAnnotationState(null)
                      : setAnnotationState("remove")
                  }
                >
                  {annotationState === "remove" ? "Stop Erasing" : "Eraser"}
                </Button.Outline>
                {isMobile ? (
                  <Button.Outline
                    onClick={() => {
                      setNote(true);
                      setTimeout(() => setNote(false));
                      annotationState === "new-note"
                        ? setAnnotationState(null)
                        : setAnnotationState("new-note");
                    }}
                  >
                    {annotationState === "new-note" ? "Done" : "New Note"}
                  </Button.Outline>
                ) : (
                  <Button.Outline
                    onClick={() => {
                      setNote(true);
                      setTimeout(() => setNote(false));
                    }}
                  >
                    New Note
                  </Button.Outline>
                )}

                <Button.Outline
                  onClick={() => {
                    setUndo(true);
                    setTimeout(() => setUndo(false));
                  }}
                >
                  Undo
                </Button.Outline>
                <Button.Solid
                  onClick={async () => {
                    setAnnotationState(null);
                    setShowColourPicker(false);
                    if (
                      await Dialog.confirmDelete(
                        "Are you sure you want to clear all annotations?",
                      )
                    ) {
                      clearAnnotation();
                      setClear(true);
                      setTimeout(() => setClear(false));
                    }
                  }}
                >
                  Clear
                </Button.Solid>
              </div>
              {annotationState === "remove" && (
                <div className={classes.annotationBar}>
                  <p>Eraser tool</p>
                </div>
              )}
              {(annotationState === "arrow" ||
                annotationState === "scribble") && (
                <>
                  {annotationState === "arrow" && (
                    <div className={classes.annotationBar}>
                      <p>Click twice on the page to create a new arrow.</p>
                    </div>
                  )}
                  <div className={classes.annotationBar}>
                    <p>
                      {annotationState === "arrow" ? "Arrow" : "Pen"} colour:
                    </p>
                    <div
                      className={classes.annotationColour}
                      style={{ backgroundColor: colour }}
                      onClick={() => setShowColourPicker(x => !x)}
                    >
                      {showColourPicker && (
                        <TwitterPicker
                          triangle="top-right"
                          className={classes.annotationColourPicker}
                          color={colour}
                          onChangeComplete={c => {
                            setShowColourPicker(false);
                            setColour(c.hex);
                          }}
                        />
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
            <MobilePinchZoomContainer
              enable={isMobile}
              locked={mobilePinchZoomLocked}
            >
              <Scene
                pageNumber={activePage}
                activePage={currentPage}
                onChange={() => {}}
                padding={isMobile ? 0 : fullscreen ? 100 : 400}
              >
                <Annotations
                  create={annotationState === "arrow"}
                  remove={annotationState === "remove"}
                  scribble={annotationState === "scribble"}
                  undo={undo}
                  colour={colour}
                  clear={clear}
                  note={note}
                  onDone={() => setAnnotationState(null)}
                  padding={fullscreen ? 100 : 400}
                />
              </Scene>
            </MobilePinchZoomContainer>
            {isMobile && (
              <ViewerPagesFooterPanel
                pages={pages}
                activePage={activePage}
                setActivePage={setActivePage}
              />
            )}
          </div>
        </EditorProvider>
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: props => props.height - 70,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "white",
    "@media (max-width: 480px)": {
      overflowY: "hidden",
    },
  },
  title: {
    backgroundColor: "#333",
    color: "#fff",
    fontSize: 24,
    clear: "both",
    overflow: "hidden",
    transition: "0.3s ease",
    transitionProperty: "height, padding",
    height: (props: any) => (props.fullscreen ? 0 : 50),
    padding: (props: any) => (props.fullscreen ? 0 : 10),
    "& aside, a": {
      float: "right",
      fontSize: 16,
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
      textDecoration: "none",
      color: "white !important",
      padding: 5,
      "& span": {
        marginLeft: 5,
      },
    },
  },
  viewer: {
    height: (props: any) => (props.fullscreen ? "100%" : "calc(100% - 50px)"),
    display: "flex",
    flexDirection: "row",
    position: "relative",
  },
  centre: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    overflow: "auto",
  },
  centered: {
    height: props => props.height,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 480px)": {
      overflowY: "hidden",
    },
  },
  annotations: {
    position: "absolute",
    right: 10,
    top: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    "@media (max-width: 480px)": {
      right: 0,
      left: 0,
      top: 0,
    },
  },
  annotationBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: "#fff",
    padding: [0, 2.5],
    zIndex: 10000,
    marginBottom: 5,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",

    "& button": {
      margin: [5, 2.5],
      padding: [3, 10],
    },

    "& p": {
      margin: [3, 5],
    },
    "@media (max-width: 480px)": {
      alignItems: "stretch",
    },
  },
  annotationColour: {
    border: "1px solid #000",
    width: 40,
    height: 30,
    position: "relative",
    margin: 5,
  },
  annotationColourPicker: {
    position: "absolute",
    top: 40,
    right: 234,
  },
  fullscreen: {
    position: "absolute",
    top: 10,
    left: (props: any) => (props.fullscreen ? 10 : 310),
    zIndex: 10000,
    height: 30,
    width: 30,
    backgroundColor: "rgba(255, 255, 255, 0.8)",
    transition: "0.3s ease",
    transitionProperty: "background-color, left",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",

    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 1)",
    },
  },
}));

export default Viewer;
