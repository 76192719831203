import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, signInWithPopup, OAuthProvider } from "firebase/auth";

let firebaseApp;
let analytics;

const FIREBASE_CONF = {
  apiKey: "AIzaSyB_NUwWAaQPUCwlxVPRwamOASai72KNjyA",
  authDomain: "lifearc-toolkit.firebaseapp.com",
  projectId: "lifearc-toolkit",
  storageBucket: "lifearc-toolkit.appspot.com",
  messagingSenderId: "1044523952144",
  appId: "1:1044523952144:web:fd8ecf73fe90a200f58295",
  measurementId: "G-005WXEG4JZ",
};

export const useSocialLogin = () => {
  const initFirebase = () => {
    try {
      if (!firebaseApp) {
        firebaseApp = initializeApp(FIREBASE_CONF);
        analytics = getAnalytics(firebaseApp);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getMicrosoftLoginData = async () => {
    var provider = new OAuthProvider("microsoft.com");
    try {
      const auth = getAuth();
      const result = await signInWithPopup(auth, provider);
      console.log(result);
      const credential = OAuthProvider.credentialFromResult(result);
      return [
        {
          ...result,
          credential,
        },
        null,
      ];
    } catch (err) {
      return [null, err];
    }
  };

  useEffect(() => {
    initFirebase();
  }, []);

  return { getMicrosoftLoginData };
};
