import { useEffect, useState } from "react";
import { useNode, UserComponent } from "@craftjs/core";
import { createUseStyles } from "react-jss";
import ContentEditable from "react-contenteditable";

import { Theme } from "theme";
import DragBox from "../DragBox";

interface HeadingProps {
  text: string;
  small?: boolean;
}
/**
 * @deprecated use RichText instead
 */
const Heading: UserComponent<HeadingProps> = ({ text, small = false }) => {
  const {
    connectors: { connect },
    actions: { setProp },
    selected,
  } = useNode(state => ({
    selected: state.events.selected,
  }));
  const [editable, setEditable] = useState(false);
  const classes = useStyles({ selected, small });

  useEffect(() => {
    if (!selected) setEditable(false);
  }, [selected]);

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
      onClick={() => selected && setEditable(true)}
    >
      <DragBox show={selected} />
      <ContentEditable
        html={text}
        disabled={!editable}
        onChange={evt => {
          const text = evt.target.value
            .replace(/<div>/gi, "<br>")
            .replace(/<\/div>/gi, "");
          setProp(props => (props.text = text));
        }}
        tagName="p"
        className={classes.editable}
      />
    </div>
  );
};
interface StyleProps {
  selected: boolean;
  small: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: "relative",
    borderStyle: "dashed",
    borderColor: (props: StyleProps) =>
      props.selected ? "#338bdd" : "transparent",
    borderWidth: 2,
  },
  editable: {
    margin: 0,
    fontSize: (props: StyleProps) => (props.small ? "26pt" : "40pt"),
    color: (props: StyleProps) =>
      props.small ? theme.mcdonalds.yellow : "black",
    fontFamily: "Speedee, Arial, sans-serif",
    fontWeight: "bolder",
  },
}));

export default Heading;
