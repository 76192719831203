import { createUseStyles } from "react-jss";
import { Element, useNode, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";
import SettingsButton, {
  SettingsButtonGroup,
} from "../../settings/SettingsButton";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "visible",
    flexShrink: 0,
  },
  fullScreen: {
    width: "100%",
    height: "100%",
    display: "flex",
  },
}));

interface PageProps {
  variant?: string;
}

const Chapter: UserComponent<PageProps> = ({ variant = "default" }) => {
  const classes = useStyles();
  const {
    connectors: { connect },
  } = useNode();

  let layout = <></>;

  switch (variant) {
    default:
      layout = (
        <div className={classes.fullScreen}>
          <Element id="chapter-dropzone-fullscreen" is={Dropzone} canvas />
        </div>
      );
      break;
  }

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
    >
      {layout}
    </div>
  );
};

const PageSettings = () => {
  const {
    variant,
    actions: { setProp },
  } = useNode(node => ({ variant: node.data.props.variant ?? "default" }));

  return (
    <>
      <SettingsButtonGroup label="Template" noMargin>
        <SettingsButton
          onMouseDown={() => setProp(props => (props.variant = "default"))}
          active={variant === "default"}
        >
          Default
        </SettingsButton>
      </SettingsButtonGroup>
    </>
  );
};

Chapter.craft = {
  related: {
    settings: PageSettings,
  },
};

export default Chapter;
