import { createUseStyles } from "react-jss";
import { Element, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#ffbc0d",
    display: "flex",
  },
  inner: {
    flex: 1,
    margin: [25, 15],
    display: "flex",
  },
}));

const Quotation: UserComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <Element id="quotation-dropzone" is={Dropzone} canvas />
      </div>
    </div>
  );
};

export default Quotation;
