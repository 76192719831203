import { useState } from "react";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";
import { Helmet } from "react-helmet-async";

import { Theme } from "theme";
import { Button, Dialog, NavRight } from "components";
import { usePlaybookStore, useUIState } from "hooks";
import { toSlug } from "utils";
import { TPlaybook } from "type";
import EditorTheme from "../../themes";

const Create = () => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const history = useHistory();
  const createPlaybook = usePlaybookStore(state => state.createPlaybook);
  const [{ loading }, setUI] = useUIState();

  const submit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setUI({ loading: true });
    const [res, err] = await createPlaybook({
      name: name,
      thumbnail: "",
      content: { pages: [] },
    } as TPlaybook);
    setUI({ loading: false });
    if (Dialog.checkAndShowError(res, err)) return;
    history.push(`/editor/${res.ID}-${toSlug(name)}`);
  };

  const backToList = () => {
    history.push("/");
  };

  return (
    <div>
      <Helmet>
        <title>
          {EditorTheme.playbookAltText} | Create {EditorTheme.playbookAltText}
        </title>
      </Helmet>
      <NavRight>
        <Button.Solid bgColor={EditorTheme.linkColor} onClick={backToList}>
          Back to list
        </Button.Solid>
      </NavRight>
      <form onSubmit={submit} className={classes.loginForm}>
        <h1>Add a new {EditorTheme.playbookAltText}</h1>
        <label className={classes.label}>
          Name:
          <input
            type="text"
            value={name}
            onChange={e => setName(e.target.value)}
            required
            className={classes.input}
          />
        </label>
        <Button.Solid full loading={loading}>
          Add
        </Button.Solid>
      </form>
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  loginForm: {
    width: 500,
    display: "flex",
    margin: "auto",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 20px",
    backgroundColor: "#E5E5E5",
    marginTop: 40,
    "@media screen and (max-width: 768px)": {
      width: "90%",
      marginLeft: "10%",
    },
    "& h1": {
      marginTop: 0,
      marginBottom: 10,
    },
  },
  label: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    fontWeight: "bold",
  },
  input: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    marginTop: 5,
    height: 40,
    outline: "none",
    padding: "5px 10px",
    fontSize: 16,
  },
}));

export default Create;
