import clsx from "clsx";
import { createUseStyles } from "react-jss";
import { Theme } from "theme";

interface SettingsButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  active?: boolean;
}

const SettingsButton: React.FC<SettingsButtonProps> = ({
  active = false,
  className,
  ...props
}) => {
  const classes = useStyles({ active });

  return <button className={clsx(classes.button, className)} {...props} />;
};

interface SettingsButtonGroupProps {
  label?: string;
  noMargin?: boolean;
}

export const SettingsButtonGroup: React.FC<SettingsButtonGroupProps> = ({
  label,
  noMargin,
  children,
}) => {
  const classes = useStyles({ noMargin });

  return (
    <div className={classes.label}>
      {label}
      <div className={classes.group}>{children}</div>
    </div>
  );
};

interface StyleProps {
  active?: boolean;
  noMargin?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  button: {
    border: "1px solid #ccc",
    borderRadius: 3,
    maxHeight: 30,
    minWidth: 30,
    flex: 1,
    transition: "background-color 0.2s ease",
    backgroundColor: (props: StyleProps) => (props.active ? "#eee" : "white"),

    "&:hover": {
      backgroundColor: "#eee",
      cursor: "pointer",
    },

    "& > svg": {
      height: "100%",
      width: 20,
    },
  },
  label: {
    marginBottom: (props: StyleProps) => (props.noMargin ? 0 : 15),
  },
  group: {
    marginTop: 10,
    minHeight: 30,
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    gap: 10,
  },
}));

export default SettingsButton;
