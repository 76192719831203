import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import { Helmet } from "react-helmet-async";
import { useRouteMatch } from "react-router";
import { TFirebaseV9AuthResponse } from "type";
import { Theme } from "theme";
import { Button } from "components";
import { useStore, useUIState, useSocialLogin } from "hooks";
import { CONFIG, fetchApi } from "utils";
import { SiMicrosoft } from "react-icons/si";
import { domains, getSubDomains } from "domains";

const shouldShowSocialLogin = () => {
  if (getSubDomains() !== domains.lifearc) return false;
  // dev
  // if (!window.location.href.includes("social_login=1")) return false;
  return true;
};

const Login = () => {
  const classes = useStyles();
  const showSocialLogin = shouldShowSocialLogin();
  const { getMicrosoftLoginData } = useSocialLogin();
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const login = useStore(state => state.login);
  const loginMicrosoft = useStore(state => state.loginMicrosoft);
  const [{ errorMes, loading }, setUI] = useUIState();
  const route =
    useRouteMatch<{ token?: string; action?: string }>("/:action/:token");
  const [playbook, setPlaybook] = useState<string>();

  const token = route?.params.token;

  let action: string = "";

  switch (route?.params.action) {
    case "editor":
      action = "Edit ";
  }

  useEffect(() => {
    if (token) {
      const [id] = token.split("-");
      fetchApi(`/api/playbooks/info/${id}`).then(res => {
        if (res?.data) {
          setPlaybook(res.data.name);
        }
      });
    }
  }, [token]);

  const handleLogin = async (evt: React.SyntheticEvent) => {
    evt.preventDefault();
    setUI({ errorMes: "", loading: true });
    const [, err] = await login(email, password);
    if (err) return setUI({ errorMes: String(err), loading: false });
    else setUI({ errorMes: "", loading: false });
  };

  const handleButtonMicrosoft = async e => {
    e.preventDefault();

    const [data, error] = await getMicrosoftLoginData();
    if (error) return console.error(error);
    const { user, credential } = data as TFirebaseV9AuthResponse;
    const { email, displayName } = user;
    const LIST_ACCEPT_DOMAINS = [
      "lifearc.org",
      "codesigned.co.uk",
      "theatro360.com",
      "gmail.com",
    ];
    const isAccepted = (() => {
      let bool = false;
      for (let i = 0; i < LIST_ACCEPT_DOMAINS.length; i++) {
        if (email.includes(LIST_ACCEPT_DOMAINS[i])) {
          return true;
        }
      }
      return false;
    })();

    if (!isAccepted) {
      return setUI({
        errorMes: "Your email is not allowed to login.",
        loading: false,
      });
    }
    setUI({ errorMes: "", loading: true });
    const [, err] = await loginMicrosoft(credential.accessToken);
    if (err) return setUI({ errorMes: String(err), loading: false });
    else setUI({ errorMes: "", loading: false });
  };

  return (
    <div>
      <Helmet>
        <title>
          {CONFIG.NAME} | {playbook ? `${action}${playbook}` : "Log in"}
        </title>
      </Helmet>
      <form onSubmit={handleLogin} className={classes.loginForm}>
        <h1>Login</h1>
        {errorMes && <p>{errorMes}</p>}
        <label className={classes.label}>
          Email:
          <input
            type="text"
            value={email}
            onChange={e => setEmail(e.target.value)}
            className={classes.input}
          />
        </label>
        <label className={classes.label}>
          Password:
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            className={classes.input}
          />
        </label>
        <Button.Solid full loading={loading} marginBottom={10}>
          Login
        </Button.Solid>
        <Button.Outline
          full
          onClick={() => {
            window.open(
              `https://${
                CONFIG.SUBDOMAIN ? CONFIG.SUBDOMAIN + "." : ""
              }guide.theatro360.com`,
              "_blank",
            );
          }}
        >
          Register
        </Button.Outline>
        {showSocialLogin && (
          <>
            <p style={{ textAlign: "center", marginBottom: 5 }}>Or</p>
            <Button.Outline onClick={handleButtonMicrosoft} full marginTop={10}>
              <SiMicrosoft
                style={{ marginRight: 10, transform: "translateY(3px)" }}
              />
              Login with Microsoft
            </Button.Outline>
          </>
        )}
      </form>
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  loginForm: {
    width: 500,
    display: "flex",
    margin: "auto",
    flexDirection: "column",
    alignItems: "center",
    padding: "40px 20px",
    backgroundColor: "#E5E5E5",
    marginTop: 40,
    "@media screen and (max-width: 768px)": {
      width: "90%",
      marginLeft: "5%",
    },
    "& h1": {
      marginTop: 0,
      marginBottom: 10,
    },
  },
  label: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    fontWeight: "bold",
  },
  input: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    marginTop: 5,
    height: 40,
    outline: "none",
    padding: "5px 10px",
    fontSize: 16,
  },
}));

export default Login;
