import LifeArcTemplate from "./OneLifeArc";
import McDonaldTemplate from "./McDonald";

import {
  Chapter as LifeArcChapter,
  Cover as LifeArcCover,
  Page as LifeArcPage,
  Quotation as LifeArcQuotation,
} from "./OneLifeArc";

import {
  Chapter as McDonaldChapter,
  Cover as McDonaldCover,
  Page as McDonaldPage,
  Quotation as McDonaldQuotation,
} from "./McDonald";
import { domains, getSubDomains } from "domains";

let Template: typeof LifeArcTemplate | typeof McDonaldTemplate;
let Chapter: typeof LifeArcChapter | typeof McDonaldChapter;
let Cover: typeof LifeArcCover | typeof McDonaldCover;
let Page: typeof LifeArcPage | typeof McDonaldPage;
let Quotation: typeof LifeArcQuotation | typeof McDonaldQuotation;

if (process.env.NODE_ENV === "production") {
  switch (getSubDomains()) {
    case domains.lifearc:
      Template = LifeArcTemplate;
      Chapter = LifeArcChapter;
      Cover = LifeArcCover;
      Page = LifeArcPage;
      Quotation = LifeArcQuotation;
      break;

    case domains.mcdonald:
    default:
      Template = McDonaldTemplate;
      Chapter = McDonaldChapter;
      Cover = McDonaldCover;
      Page = McDonaldPage;
      Quotation = McDonaldQuotation;
      break;
  }
} else {
  Template = LifeArcTemplate;
  Chapter = LifeArcChapter;
  Cover = LifeArcCover;
  Page = LifeArcPage;
  Quotation = LifeArcQuotation;
}

export { Chapter, Cover, Page, Quotation };
export default Template;
