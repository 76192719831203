import { changeAPISubdomain, CONFIG } from "./utils";

export const checkMultiSiteSetup = async () => {
  if (window.location.hostname.indexOf("localhost") === 0) return;
  if (window.location.hostname.split(".").length !== 4) return;
  const res = await fetch(
    "https://api-guide.theatro360.com/api/users/site-info",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        subdomain: window.location.hostname.split(".")[0],
      }),
    },
  );
  const json = await res.json();
  const data = !!json && !!json.data ? json.data : {};
  const { subDomain, image, backgroundColor, branchColor, playbooks } = data;
  if (!subDomain) return;
  changeAPISubdomain(subDomain);
  if (branchColor)
    document.documentElement.style.setProperty("--primary-color", branchColor);
  if (backgroundColor)
    document.documentElement.style.setProperty(
      "--background-color",
      backgroundColor,
    );
  CONFIG.LOGO = !!image ? image : CONFIG.LOGO;
  try {
    CONFIG.NAME = playbooks
      ? "Playbooks"
      : subDomain === "lifearc"
      ? ""
      : subDomain[0].toUpperCase() + subDomain.slice(1, subDomain.length);
  } catch (err) {
    CONFIG.NAME = "Playbooks";
  }
};
