import { Element, useNode, UserComponent } from "@craftjs/core";
import { createUseStyles } from "react-jss";

import { Theme } from "theme";
import DragBox from "../DragBox";
import Dropzone from "../Dropzone";

interface StyleProps {
  selected: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: "relative",
    flex: 1,
    minHeight: 100,
    borderStyle: "dashed",
    borderColor: (props: StyleProps) =>
      props.selected ? "#338bdd" : "transparent",
    borderWidth: 2,
    display: "flex",
    padding: [0, 0],
    flexDirection: "row",
    "& > *:nth-child(2)": {
      marginRight: "2.5%",
    },
    "& > *:nth-child(3)": {
      marginLeft: "2.5%",
    },
  },
}));

const TwoCol: UserComponent = () => {
  const {
    connectors: { connect },
    selected,
  } = useNode(state => ({
    selected: state.events.selected,
  }));
  const classes = useStyles({ selected });

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
    >
      <DragBox show={selected} />
      <Element id="first-col" is={Dropzone} canvas />
      <Element id="second-col" is={Dropzone} canvas />
    </div>
  );
};

TwoCol.craft = {
  displayName: "Two Column Layout",
};

export default TwoCol;
