import { createUseStyles } from "react-jss";
import { Theme } from "theme";
interface ILoadingProps {
  color?: string;
  white?: boolean;
  size?: number;
}

const Loading = (props: ILoadingProps) => {
  const classes = useStyles();

  const colorStyle = (() => {
    const obj: any = {};
    obj.backgroundColor = (() => {
      if (props.color) return props.color;
      if (props.white) return "white";
      return undefined;
    })();
    return obj;
  })();

  const sizeStyle = (() => {
    const obj: any = {};
    obj.width = (() => {
      if (props.size) return props.size;
      return undefined;
    })();
    obj.height = obj.width;
    return obj;
  })();

  return (
    <div className={classes.loadSpinner}>
      <div className={classes.spinner} style={sizeStyle}>
        <div className={classes.dot1} style={colorStyle} />
        <div className={classes.dot2} style={colorStyle} />
      </div>
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  loadSpinner: {
    animationName: "fade-in",
    animationDuration: "1s",
    pointerEvents: "inherit",
    zIndex: 10,
  },
  spinner: {
    width: 25,
    height: 25,
    position: "relative",
    textAlign: "center",
    pointerEvents: "inherit",
    animation: "$sk-rotate 2s infinite linear",
  },
  dot1: {
    width: "60%",
    height: "60%",
    display: "inline-block",
    position: "absolute",
    top: 0,
    backgroundColor: "#162a08",
    borderRadius: "100%",
    pointerEvents: "inherit",

    animation: "$sk-bounce 2s infinite ease-in-out",
  },
  dot2: {
    extend: "dot1",
    top: "auto",
    bottom: 0,
    animationDelay: "-1s",
    pointerEvents: "inherit",
  },
  "@keyframes fade-in": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  "@keyframes sk-bounce": {
    "0%": { transform: "scale(0)" },
    "100%": { transform: "scale(0)" },
    "50%": { transform: "scale(1)" },
  },
  "@keyframes sk-rotate": {
    "100%": { transform: "rotate(360deg)" },
  },
}));

export default Loading;
