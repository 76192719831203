import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  RouteProps,
  Redirect,
} from "react-router-dom";
import shallow from "zustand/shallow";
import { ThemeProvider } from "react-jss";
import qs from "qs";

import useStore from "./hooks/useStore";
import fetchApi from "./utils/fetchApi";
// import Home from "./pages/Home";
import Login from "./pages/Login";
import Viewer from "./pages/Viewer";
import PdfView from "./pages/PdfView";
import Editor from "./pages/editor/Editor";
import CreatePlaybook from "./pages/editor/Create";
import LoginFromGuidedTour from "./pages/LoginFromGuidedTour";
import Homepage from "./pages/Homepage";
import { Loading, Nav } from "./components";
import theme from "./theme";
import { HelmetProvider } from "react-helmet-async";

const AuthenicatedRoute: React.FC<RouteProps & { admin?: boolean }> = ({
  admin,
  ...props
}) => {
  const user = useStore(state => state.user);

  if (user) {
    if (!admin || user.role === "admin") {
      return <Route {...props} />;
    } else {
      return <Redirect to="/" />;
    }
  } else {
    return <Route {...props} component={Login} />;
  }
};

const App: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [setToken, logout, setUser] = useStore(
    state => [state.setToken, state.logout, state.setUser],
    shallow,
  );

  useEffect(() => {
    const token = (() => {
      const fromLocal = localStorage.getItem("token");
      if (!!fromLocal) return fromLocal;
      const query = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      const fromQueryString = query.token;
      if (fromQueryString) return fromQueryString;
      return null;
    })();
    if (token) {
      setToken(token);
      fetchApi("/api/users/me").then(data => {
        if (data.success) {
          const { ID, name, email, avatar, role } = data.data;
          setUser({ ID: ID, name, email, avatar, role });
          setLoading(false);
        } else {
          logout();
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
    }
  }, [logout, setToken, setUser]);

  if (loading)
    return (
      <div
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Loading />
      </div>
    );

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <BrowserRouter>
          <Nav>
            <Switch>
              <AuthenicatedRoute exact path="/" component={Homepage} />
              <AuthenicatedRoute exact path="/view/:token" component={Viewer} />
              <AuthenicatedRoute
                admin
                exact
                path="/editor/new"
                component={CreatePlaybook}
              />
              <AuthenicatedRoute
                admin
                exact
                path="/editor/:token"
                component={Editor}
              />
              <Route
                exact
                path="/from-guided-tour/:token"
                component={LoginFromGuidedTour}
              />
              <Route exact path="/pdf-view/:token" component={PdfView} />
            </Switch>
          </Nav>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
