import { useMemo, useRef } from "react";
import { isEqual } from "lodash";

const useDeepMemo = <T>(
  factory: () => T,
  dependencies: React.DependencyList,
) => {
  const ref = useRef<React.DependencyList>([]);

  if (!isEqual(ref.current, dependencies)) {
    ref.current = dependencies;
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  return useMemo<T>(factory, ref.current);
};

export default useDeepMemo;
