import { useEffect, useState } from "react";
import { createUseStyles } from "react-jss";
import ContentEditable from "react-contenteditable";

import { TPlaybook } from "type";
import { Theme } from "theme";
import EditorTheme from "themes";

type Sections = {
  page: TPlaybook["content"]["pages"][number];
  subsections: TPlaybook["content"]["pages"];
}[];

interface ViewerPagesPanelProps {
  pages: TPlaybook["content"]["pages"];
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  show?: boolean;
}

const ViewerPagesPanel: React.FC<ViewerPagesPanelProps> = ({
  pages,
  activePage,
  setActivePage,
  show = false,
}) => {
  const classes = useStyles({ show });
  const [sections, setSections] = useState<Sections>([]);
  const [activeSection, setActiveSection] = useState<number>(0);

  const activeId = pages[activePage].id;

  const getIndex = (id: string) => pages.findIndex(page => page.id === id);

  useEffect(() => {
    let sections: Sections = [];
    let current = null;
    let subsections = [];
    for (const page of pages) {
      if (current === null) {
        current = page;
      } else {
        if (["cover", "chapter"].includes(page.template)) {
          sections.push({ page: current, subsections });
          subsections = [];
          current = page;
        } else {
          subsections.push(page);
        }
      }
    }
    if (current !== null) {
      sections.push({ page: current, subsections });
    }
    setSections(sections);
  }, [pages]);

  return (
    <div className={classes.container}>
      <div className={classes.pages}>
        {sections.map((section, index) => {
          const ids = [section.page.id, ...section.subsections.map(p => p.id)];
          const active = ids.includes(activeId);
          return (
            <div key={section.page.id}>
              <div
                className={classes.page}
                style={{
                  borderColor: active ? EditorTheme.selectedColor : "#ccc",
                }}
                onClick={() => {
                  setActivePage(getIndex(section.page.id));
                  setActiveSection(index);
                }}
              >
                <ContentEditable
                  tagName="p"
                  html={section.page.name}
                  disabled
                  onChange={() => {}}
                />
              </div>
              <div
                className={classes.section}
                style={{
                  maxHeight:
                    activeSection === index
                      ? section.subsections.length * 60
                      : 0,
                }}
              >
                {section.subsections.map(subsection => (
                  <div
                    key={subsection.id}
                    className={classes.subsection}
                    style={{
                      color: activeId === subsection.id ? "#000" : "#888",
                    }}
                    onClick={() => setActivePage(getIndex(subsection.id))}
                  >
                    <ContentEditable
                      tagName="p"
                      html={subsection.name}
                      disabled
                      onChange={() => {}}
                    />
                  </div>
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type StyleProps = {
  show: boolean;
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    flex: 1,
    transition: "max-width 0.3s ease",
    maxWidth: (props: StyleProps) => (props.show ? 300 : 0),
    position: "relative",
    overflowX: "hidden",
    overflowY: "auto",
  },
  pages: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    padding: [5, 0],
    overflow: "auto",
  },
  page: {
    border: "2px solid",
    borderRadius: 3,
    margin: [5, 10],
    padding: [5, 20],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    cursor: "pointer",

    "& p": {
      margin: 0,
    },
  },
  section: {
    transition: "max-height 0.5s ease",
    overflow: "hidden",
  },
  subsection: {
    margin: [0, 10, 0, 30],
    padding: [5, 0],
    cursor: "pointer",
    transition: "color 0.2s ease",

    "& p": {
      margin: 0,
    },

    "&:hover": {
      color: "#000 !important",
    },
  },
}));

export default ViewerPagesPanel;
