import { createUseStyles } from "react-jss";
import { Element, UserComponent } from "@craftjs/core";

import { Theme } from "theme";
import Dropzone from "../../Dropzone";

import goldenArches from "images/golden-arches.png";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    width: 1131,
    height: 640,
    backgroundColor: "#fff",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flexShrink: 0,
  },
  stripe: {
    width: "100%",
    height: 50,
    backgroundColor: theme.mcdonalds.red,
    position: "absolute",
    bottom: 0,
  },
  inner: {
    position: "absolute",
    left: 100,
    top: 150,
    height: 300,
    width: 400,
    zIndex: 10,
    display: "flex",

    "& > *": {
      justifyContent: "center",
    },
  },
  arches: {
    position: "absolute",
    right: 0,
    bottom: 0,
    height: "95%",
  },
}));

const Cover: UserComponent = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <div className={classes.inner}>
        <Element id="cover-dropzone" is={Dropzone} canvas />
      </div>
      <div className={classes.stripe} />
      <img src={goldenArches} className={classes.arches} alt="Golden Arches" />
    </div>
  );
};

export default Cover;
