import theme from "theme";

import coverThumbnail from "images/cover-thumbnail.png";
import pageThumbnail from "images/page-thumbnail.png";
import quotationThumbnail from "images/quotation-thumbnail.png";
import chapterThumbnail from "images/chapter-background.jpg";
import { EditorAssetsType } from "./EditorPageType";
import NoteIcon from "images/noteIcon.svg";

export const mcdonaldEditorAssets: EditorAssetsType = {
  linkColor: theme.mcdonalds.yellow,
  selectedColor: theme.mcdonalds.yellow,
  pages: [
    {
      pageType: "cover",
      text: "Cover Page",
      imageSrc: coverThumbnail,
      imageAlt: "cover page",
    },
    {
      pageType: "page",
      text: "Normal Page",
      imageSrc: pageThumbnail,
      imageAlt: "normal page",
    },
    {
      pageType: "chapter",
      text: "Chapter Page",
      imageSrc: chapterThumbnail,
      imageAlt: "chapter page",
    },
    {
      pageType: "quotation",
      text: "Quotation",
      imageSrc: quotationThumbnail,
      imageAlt: "quotation page",
    },
  ],
  fontColor: {
    black: "#000000",
    yellow: "#ffbc0d",
    red: "#c8161d",
  },
  myNotesBoxBackgroundColor: theme.mcdonalds.yellow,
  myNotesBoxFontColor: theme.mcdonalds.black,
  playbookAltText: "Playbook",

  // Quote Mark
  quoteMarkColor: theme.mcdonalds.red,
  quoteTextColor: theme.mcdonalds.black,
  noteboxColor: theme.mcdonalds.yellow,
  noteboxContentBackgroundColor: "#fff2c7",
  noteboxBinColor: theme.mcdonalds.red,
  noteBoxIcon: NoteIcon,
};
