import { useEditor } from "@craftjs/core";
import { createUseStyles } from "react-jss";
import { Theme } from "theme";
import RichText, { Text, Typography } from "../elements/RichText";
import Image from "../elements/Image";
import TwoCol from "../elements/TwoCol";
import NoteLocation from "../elements/NoteLocation";
import Quote from "../elements/Quote";
import { Button } from "components";

const ComponentsBar = ({ onSave }: any) => {
  const { connectors, query } = useEditor();
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <p>Drag to add:</p>
      <button
        ref={(ref: HTMLButtonElement) =>
          connectors.create(
            ref,
            <RichText lineHeight={1.2}>
              <Typography>
                <Text text="Click to edit text" />
              </Typography>
            </RichText>,
          )
        }
      >
        Text
      </button>
      <button
        ref={(ref: HTMLButtonElement) => connectors.create(ref, <Image />)}
      >
        Image
      </button>
      <button
        ref={(ref: HTMLButtonElement) => connectors.create(ref, <TwoCol />)}
      >
        Two Columns
      </button>
      <button
        ref={(ref: HTMLButtonElement) =>
          connectors.create(ref, <NoteLocation />)
        }
      >
        Note Box
      </button>
      <button
        ref={(ref: HTMLButtonElement) =>
          connectors.create(
            ref,
            <Quote quote="Click to edit text" author="Author" />,
          )
        }
      >
        Quote
      </button>
      <div style={{ flex: 1 }} />
      <Button.Solid onClick={() => onSave(query.serialize())}>
        Save
      </Button.Solid>
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    backgroundColor: "#fff",
    margin: 10,
    height: 50,
    borderRadius: 3,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: [0, 5],
    position: "fixed",
    width: "calc(100% - 620px)",
    overflowX: "auto",

    zIndex: 10, // set the priority of the tools bar

    "& *": {
      margin: [0, 5],
    },

    "& > button:last-child": {
      borderRadius: 3,
    },
  },
}));

export default ComponentsBar;
