import React, { useState, useCallback } from "react";

import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

interface IProps {
  enable: boolean;
  locked: boolean;
  children: any;
}
const PinchZoomContainer = ({ enable, children, locked }: IProps) => {
  const [containerHeight, setHeight] = useState(500);
  const [containerWidth, setWidth] = useState(window.innerWidth);
  // const containerRef = useRef<any>(null);

  const containerRef = useCallback(node => {
    if (node !== null) {
      setHeight(node.getBoundingClientRect().height);
      setWidth(node.getBoundingClientRect().width);
    }
  }, []);

  if (!enable) {
    return children;
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        // DEBUG
        // backgroundColor: 'pink',
      }}
      ref={containerRef}
    >
      <TransformWrapper
        initialScale={1}
        initialPositionX={0}
        initialPositionY={0}
        disabled={locked}
      >
        {({ zoomIn, zoomOut, resetTransform, ...rest }) => (
          <React.Fragment>
            <TransformComponent>
              <div style={{ width: containerWidth, height: containerHeight }}>
                {children}
              </div>
            </TransformComponent>
          </React.Fragment>
        )}
      </TransformWrapper>
    </div>
  );
};

export default PinchZoomContainer;
