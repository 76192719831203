import { createUseStyles } from "react-jss";
import { Theme } from "theme";

interface SettingsRangeProps {
  label: string;
  value: number;
  onChange: (value: number) => void;
  min: string;
  max: string;
  step: string;
}

const SettingsRange: React.FC<SettingsRangeProps> = ({
  value,
  onChange,
  ...props
}) => {
  const classes = useStyles();

  return (
    <label>
      {props.label}
      <input
        type="range"
        value={value.toString()}
        onChange={evt => onChange(parseInt(evt.target.value))}
        className={classes.input}
        {...props}
      />
    </label>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  input: {
    display: "flex",
    flexDirection: "column",
    marginBottom: 15,
    width: "100%",
  },
}));

export default SettingsRange;
