import create from "zustand";
import { TUser, TErrorInArray } from "type";
import { fetchApi, errorInArray } from "utils";

interface State {
  user: TUser | null;
  setUser: (user: TUser) => void;
  token: string | null;
  setToken: (token: string) => void;
  logout: () => void;
  login: (
    email: string,
    password: string,
  ) => TErrorInArray<{
    token: string;
    user: TUser;
  }>;
  loginMicrosoft: (token: string) => TErrorInArray<{
    token: string;
    user: TUser;
  }>;
}

const useStore = create<State>(set => ({
  user: null,
  setUser: (user: TUser) => set({ user }),
  token: null,
  setToken: (token: string) => {
    localStorage.setItem("token", token);
    set({ token });
  },
  logout: () => {
    localStorage.removeItem("token");
    set({ token: null, user: null });
  },
  login: async (email, password) => {
    return errorInArray(async () => {
      const res = await fetchApi("/api/users/login", "POST", {
        email,
        password,
      });
      if (res.error) throw new Error(res.error);
      if (res.data) {
        localStorage.setItem("token", res.data.token);
        set({ token: res.data.token, user: res.data.user });
      }
      return res.data;
    });
  },
  loginMicrosoft: async (token: string) => {
    return errorInArray(async () => {
      const res = await fetchApi("/api/users/login-microsoft", "POST", {
        access_token: token,
      });
      if (res.error) throw new Error(res.error);
      if (res.data) {
        localStorage.setItem("token", res.data.token);
        set({ token: res.data.token, user: res.data.user });
      }
      return res.data;
    });
  },
}));

export default useStore;
