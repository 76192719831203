import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { Theme } from "theme";

interface ModalProps {
  show: boolean;
  close?: () => void;
}

const Modal: React.FC<ModalProps> = ({ show: showProp, close, children }) => {
  const [show, setShow] = useState(showProp);
  const [opacity, setOpacity] = useState(showProp);
  const classes = useStyles({ show: opacity });
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    setTimeout(() => setOpacity(showProp));
  }, [showProp]);

  useEffect(() => {
    if (showProp) {
      setShow(true);
    } else {
      timeoutRef.current = setTimeout(() => setShow(false), 500);
    }
    return () => clearTimeout(timeoutRef.current);
  }, [showProp]);

  return (
    show && (
      <div className={classes.modal} onClick={close}>
        {children}
      </div>
    )
  );
};

interface StyleProps {
  show: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  modal: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    opacity: (props: StyleProps) => (props.show ? 1 : 0),
    transition: "opacity 0.5s ease",
    zIndex: 1000,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Modal;
