import { useState } from "react";
import { createUseStyles } from "react-jss";
import { SVGProps } from "react";

import { TPlaybook } from "type";
import { Theme } from "theme";
import ViewerPagesPanel from "./ViewerPagesPanel";
import { useWindowSize } from "hooks";

const BurgerMenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg viewBox="0 0 100 80" width={40} height={40} {...props}>
    <path d="M0 0h100v10H0zM0 30h100v10H0zM0 60h100v10H0z" />
  </svg>
);

// type Sections = {
//   page: TPlaybook["content"]["pages"][number];
//   subsections: TPlaybook["content"]["pages"];
// }[];

interface ViewerPagesPanelProps {
  pages: TPlaybook["content"]["pages"];
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
}

const ViewerPagesFooterPanel: React.FC<ViewerPagesPanelProps> = ({
  pages,
  activePage,
  setActivePage,
}) => {
  const [show, showDrawer] = useState(false);
  const windowSize = useWindowSize();
  const classes = useStyles({ show, ...windowSize });

  // const getIndex = (id: string) => pages.findIndex(page => page.id === id);

  // console.log('sections', sections);
  // console.log('active page', pages[activePage]);

  return (
    <div className={classes.container}>
      {/* <div></div> */}
      <div className={classes.pageName}>{pages[activePage].name}</div>
      <div className={classes.burgerMenu} onClick={() => showDrawer(!show)}>
        <BurgerMenuIcon />
      </div>
      <div className={classes.slideInDrawer}>
        <ViewerPagesPanel
          pages={pages}
          activePage={activePage}
          setActivePage={(p: number) => {
            setActivePage(p);
            showDrawer(false);
          }}
          show={true}
        />
      </div>
    </div>
  );
};

type StyleProps = {
  show: boolean;
  width: number;
  height: number;
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    // width: "100%",
    height: 60,
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
  },
  pageName: {
    fontSize: 18,
    textAlign: "center",
    padding: "5px 15px",
  },
  burgerMenu: {
    padding: "5px 10px",
    cursor: "pointer",
  },
  slideInDrawer: {
    position: "absolute",
    bottom: 0,
    // height: "100vh",
    height: props => props.height,
    zIndex: 100000,
    paddingTop: 70,
    overflowY: "auto",
    backgroundColor: "white",
    borderRight: `2px solid ${theme.onelifearc.green}`,
    transition: `left 0.3s`,
    left: (props: StyleProps) => (props.show ? 0 : -props.width),
    width: (props: StyleProps) => props.width - 60,
    flexDirection: "row",
  },
}));

export default ViewerPagesFooterPanel;
