import { createUseStyles } from "react-jss";
import { Theme } from "theme";
import { useEditor } from "@craftjs/core";
import { createElement } from "react";
import SettingsButton from "../settings/SettingsButton";

let renderFunction: any = () => null;
export const showToolsPanel = (comp: any) => {
  renderFunction(comp);
};
export const hideToolsPanel = () => {
  renderFunction(null);
};

const SidebarToolsPanel = () => {
  const classes = useStyles();
  const { actions, selected } = useEditor((state, query) => {
    const currentNodeId = state.events.selected;
    let selected:
      | {
          id: string;
          name: string;
          settings?: React.ElementType<any>;
          isDeletable: boolean;
        }
      | undefined;

    if (currentNodeId) {
      const name = id => state.nodes[id].data.name;
      const ancestors = query.node(currentNodeId).ancestors();
      let settingsNodeId = currentNodeId;
      let count = 0;
      while (
        ["Typography", "NumberedList", "BulletedList", "ListItem"].includes(
          name(settingsNodeId),
        )
      ) {
        settingsNodeId = ancestors[count];
        count++;
      }
      selected = {
        id: settingsNodeId,
        name:
          state.nodes[settingsNodeId].data.displayName ?? name(settingsNodeId),
        settings: state.nodes[settingsNodeId].related?.settings,
        isDeletable: query.node(settingsNodeId).isDeletable(),
      };
    }

    if (!["div", "Dropzone"].includes(selected?.name)) {
      return {
        selected,
      };
    }
  });

  return (
    <div className={classes.container} id="settings-panel">
      {selected ? (
        <>
          <div className={classes.header}>
            <p>{selected.name}</p>
          </div>
          {selected.settings && createElement(selected.settings)}
          {selected.isDeletable && (
            <SettingsButton onClick={() => actions.delete(selected.id)}>
              Delete
            </SettingsButton>
          )}
        </>
      ) : (
        <div className={classes.centered}>Select a component to edit</div>
      )}
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    flex: 1,
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "relative",
    padding: [10, 15],
  },
  header: {
    marginBottom: 15,

    "& p": {
      margin: 0,
      fontSize: 18,
      fontWeight: 700,
    },
  },
  centered: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  },
}));

export default SidebarToolsPanel;
