import { usePlaybookStore } from "hooks";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";
import shortid from "shortid";

import { Theme } from "theme";
import { TArrowTool, TEraserTool, TPenTool, TNotesTool } from "type";
import shallow from "zustand/shallow";
import Notes from "./Notes";
import Scribble from "./Scribble";

interface AnnotationsProps {
  create: boolean;
  remove: boolean;
  note: boolean;
  scribble: boolean;
  colour: string;
  onDone: () => void;
  clear: boolean;
  undo: boolean;
  padding: number;
}

const Annotations: React.FC<AnnotationsProps> = ({
  create,
  remove,
  scribble,
  undo,
  colour,
  onDone,
  clear,
  note,
  padding,
}) => {
  const [annotation, createAnnotation, updateAnnotation] = usePlaybookStore(
    state => [state.annotation, state.createAnnotation, state.updateAnnotation],
    shallow,
  );
  // const canvasRef = useRef<HTMLDivElement>(null);
  const classes = useStyles({ active: create || remove || scribble });

  useEffect(() => {
    if (!annotation) {
      createAnnotation();
    }
  }, [annotation, createAnnotation]);

  const notes =
    !annotation || !annotation.lines
      ? []
      : annotation.lines.filter(
          (line): line is TNotesTool => line.tool === "notes",
        );

  const scribbles =
    !annotation || !annotation.lines
      ? []
      : annotation.lines.filter(
          (line): line is TPenTool | TEraserTool | TArrowTool =>
            line.tool === "pen" ||
            line.tool === "eraser" ||
            line.tool === "arrow",
        );

  const updateNotes = (newNotes: TNotesTool[]) =>
    updateAnnotation([...scribbles, ...newNotes]);

  const updateScribbles = (
    newScribbles: (TPenTool | TEraserTool | TArrowTool)[],
  ) => updateAnnotation([...newScribbles, ...notes]);

  useEffect(() => {
    if (note) {
      updateNotes([
        ...notes,
        {
          id: shortid(),
          tool: "notes",
          title: "New Note",
          content: "",
          position: { x: 25, y: 25 },
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [note]);

  return (
    <div className={classes.annotations}>
      {annotation && (
        <>
          {!(create || remove || scribble) && (
            <Notes data={notes} update={updateNotes} padding={padding} />
          )}
          <Scribble
            scribble={scribble}
            arrow={create}
            eraser={remove}
            colour={colour}
            data={scribbles}
            onDone={onDone}
            clear={clear}
            undo={undo}
            update={updateScribbles}
          />
        </>
      )}
    </div>
  );
};

interface StyleProps {
  active: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  annotations: {
    position: "absolute",
    width: 1131,
    height: 640,
    pointerEvents: (props: StyleProps) => (props.active ? "auto" : "none"),
    cursor: "crosshair",
    zIndex: 1000000,
  },
  firstClick: {
    position: "absolute",
  },
  mouse: {
    position: "absolute",
    pointerEvents: "none",
  },
}));

export default Annotations;
