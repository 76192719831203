import { useEditor, useNode } from "@craftjs/core";
import { createUseStyles } from "react-jss";
import { Theme } from "theme";

const Dropzone: React.FC = ({ children, ...props }) => {
  const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
  const {
    connectors: { connect },
  } = useNode();
  const classes = useStyles({ highlight: !children && enabled });
  return (
    <div
      ref={(ref: HTMLDivElement) => connect(ref)}
      className={classes.dropzone}
      {...props}
    >
      {children}
    </div>
  );
};

interface StyleProps {
  highlight: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  dropzone: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: (props: StyleProps) =>
      props.highlight ? "#f5f5f5" : "transparent",
  },
}));

export default Dropzone;
