import { useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import shallow from "zustand/shallow";

import { Loading } from "components";
import { useStore } from "hooks";
import { fetchApi } from "utils";

const LoginFromGuidedTour = () => {
  const { token } = useParams<{ token: string }>();
  const history = useHistory();
  const [setToken, setUser, logout] = useStore(
    state => [state.setToken, state.setUser, state.logout],
    shallow,
  );

  useEffect(() => {
    if (!token) return;
    setToken(token);
    fetchApi("/api/users/me").then(data => {
      if (data.success) {
        const { ID, name, email, avatar, role } = data.data;
        setUser({ id: ID, name, email, avatar, role });
      } else {
        logout();
      }
      history.push("/");
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Loading />
    </div>
  );
};

export default LoginFromGuidedTour;
