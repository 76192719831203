import { useRef } from "react";
import { useNode, UserComponent } from "@craftjs/core";
import { createUseStyles } from "react-jss";
import {
  CgAlignLeft as AlignLeftIcon,
  CgAlignMiddle as AlignMiddleIcon,
  CgAlignRight as AlignRightIcon,
} from "react-icons/cg";

import { Theme } from "theme";
import DragBox from "../DragBox";
import { uploadImage } from "utils/fetchApi";
import SettingsButton, {
  SettingsButtonGroup,
} from "../settings/SettingsButton";
import SettingsRange from "../settings/SettingsRange";

interface ImageProps {
  src?: string;
  width?: any;
  height?: any;
  alignHorizontal?: "left" | "center" | "right";
  alignVertical?: "up" | "center" | "down";
  objectFit?: "contain" | "cover" | "none" | "fill";
}

const alignMatches = [
  { inEditor: "left", styleValue: "flex-start" },
  { inEditor: "center", styleValue: "center" },
  { inEditor: "right", styleValue: "flex-end" },
  { inEditor: "up", styleValue: "flex-start" },
  { inEditor: "down", styleValue: "flex-end" },
];

const Image: UserComponent<ImageProps> = props => {
  const { src, width, height, alignHorizontal, alignVertical, objectFit } =
    props;
  const {
    connectors: { connect },
    selected,
  } = useNode(state => ({
    selected: state.events.selected,
  }));
  const classes = useStyles({ selected, hasImage: !!src });
  const computeHorizontalAlignStyle = (() => {
    if (!alignHorizontal) return undefined;
    return {
      alignSelf: alignMatches.find(val => val.inEditor === alignHorizontal)
        ?.styleValue,
    };
  })();
  const computeVerticalAlignStyle = (() => {
    if (!alignVertical) return undefined;
    return {
      alignItems: alignMatches.find(val => val.inEditor === alignVertical)
        ?.styleValue,
    };
  })();

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
      style={{
        ...computeHorizontalAlignStyle,
        ...computeVerticalAlignStyle,
        width: `${Math.min(Number(width.slice(0, -1)), 100)}%`,
        height:
          height === "auto"
            ? height
            : `${Math.min(Number(height.slice(0, -1)), 100)}%`,
      }}
    >
      <DragBox show={selected} />
      {src ? (
        <div className={classes.image}>
          <img
            src={src}
            style={{
              width: `${Math.max(Number(width.slice(0, -1)), 100)}%`,
              height:
                height === "auto"
                  ? height
                  : `${Math.max(Number(height.slice(0, -1)), 100)}%`,
              objectFit,
            }}
            alt=""
          />
        </div>
      ) : (
        "Image"
      )}
    </div>
  );
};

const ImageSettings = () => {
  const classes = useStyles({});
  const ref = useRef<HTMLInputElement>(null);
  const {
    width,
    height,
    alignHorizontal,
    // alignVertical,
    objectFit,
    actions: { setProp },
  } = useNode(node => ({ ...node.data.props }));

  const update = obj => {
    setProp(prop => {
      for (let key in obj) {
        prop[key] = obj[key];
      }
    });
  };

  return (
    <>
      <SettingsButtonGroup>
        <SettingsButton onClick={() => ref.current.click()}>
          Upload image
        </SettingsButton>
        <input
          ref={ref}
          id="image-upload"
          className={classes.disappear}
          type="file"
          accept="image/*"
          onChange={(evt: React.ChangeEvent<HTMLInputElement>) => {
            if (Array.from(evt.target.files).length === 0) return;
            uploadImage(evt.target.files[0])
              .then(res => {
                if (res.success) {
                  setProp(props => (props.src = res.url));
                } else {
                  sweetAlert("Error", "Image upload failed");
                }
              })
              .catch(err => {
                sweetAlert("Error", "Image upload failed");
              });
          }}
        />
      </SettingsButtonGroup>
      <SettingsButtonGroup label="Horizontal alignment">
        <SettingsButton
          onClick={() => update({ alignHorizontal: "left" })}
          active={alignHorizontal === "left"}
        >
          <AlignLeftIcon />
        </SettingsButton>
        <SettingsButton
          onClick={() => update({ alignHorizontal: "center" })}
          active={alignHorizontal === "center"}
        >
          <AlignMiddleIcon />
        </SettingsButton>
        <SettingsButton
          onClick={() => update({ alignHorizontal: "right" })}
          active={alignHorizontal === "right"}
        >
          <AlignRightIcon />
        </SettingsButton>
      </SettingsButtonGroup>
      <SettingsRange
        label={`Width: ${width}`}
        min="0"
        max="100"
        step="1"
        value={width.slice(0, -1)}
        onChange={val => update({ width: `${val}%` })}
      />
      {objectFit !== "contain" && (
        <SettingsRange
          label={`Height: ${height}`}
          min="0"
          max="200"
          step="1"
          value={height.slice(0, -1)}
          onChange={val => update({ height: `${val}%` })}
        />
      )}
      <SettingsButtonGroup label="Fit mode">
        <SettingsButton
          onClick={() => update({ objectFit: "cover", height: "auto" })}
          active={objectFit === "cover"}
        >
          Cover
        </SettingsButton>
        <SettingsButton
          onClick={() => update({ objectFit: "contain", height: "auto" })}
          active={objectFit === "contain"}
        >
          Contain
        </SettingsButton>
        <SettingsButton
          onClick={() => update({ objectFit: "fill", height: "auto" })}
          active={objectFit === "fill"}
        >
          Fill
        </SettingsButton>
      </SettingsButtonGroup>
    </>
  );
};

Image.craft = {
  props: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  related: { settings: ImageSettings },
};

interface StyleProps {
  selected?: boolean;
  hasImage?: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: "relative",
    marginTop: 5,
    boxSizing: "border-box",
    backgroundColor: (props: StyleProps) =>
      props.hasImage ? "transparent" : "#ccc",
    minHeight: (props: StyleProps) => (props.hasImage ? undefined : 50),
    borderStyle: "dashed",
    borderColor: (props: StyleProps) =>
      props.selected ? "#338bdd" : "transparent",
    borderWidth: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Speedee, Arial, sans-serif",
    fontWeight: "lighter",
  },
  image: {
    overflow: "hidden",
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
  },
  disappear: {
    display: "none",
  },
}));

export default Image;
