import { createUseStyles } from "react-jss";
import { Link } from "react-router-dom";
import { GoPencil } from "react-icons/go";
import { Frame } from "@craftjs/core";
import shallow from "zustand/shallow";

import { Theme as TypeTheme } from "theme";
import { Button, Dialog } from "../";
import { usePlaybookStore, useStore } from "hooks";
import { TPlaybook } from "type";
import { toSlug } from "utils";
import useMeasure from "react-use-measure";
import EditorProvider from "pages/editor/EditorProvider";
import swal from "sweetalert";
import EditorTheme from "themes";

interface IPlaybookItemProps {
  data: TPlaybook;
  onDelete?(id: string): void;
}

const PlaybookItem = ({ data }: IPlaybookItemProps) => {
  const user = useStore(state => state.user);
  const [ref, { width }] = useMeasure();
  const classes = useStyles({ width });
  const [deletePlaybook, createPlaybook, updatePlaybook, refresh] =
    usePlaybookStore(
      state => [
        state.deletePlaybook,
        state.createPlaybook,
        state.updatePlaybook,
        state.getListPlaybook,
      ],
      shallow,
    );
  if (!data) return null;

  const handleDelete = async () => {
    const shoudlDelete = await Dialog.confirmDelete(
      "You will not be able to recover this Playbook!",
    );
    if (!shoudlDelete) return;
    const [res, err] = await deletePlaybook(data.ID);
    Dialog.checkAndShowError(res, err);
  };

  const handleDuplicate = async () => {
    const [res, err] = await createPlaybook({
      ...data,
      name: `${data.name} (copy)`,
      ID: undefined,
    });
    Dialog.checkAndShowError(res, err);
  };

  const handleRename = async () => {
    try {
      const name = await swal({
        title: "Rename Playbook",
        content: {
          element: "input",
          attributes: {
            placeholder: "New Playbook name",
            value: data.name,
          },
        },
      });
      if (!name) return;

      const [res, err] = await updatePlaybook({
        ...data,
        name,
      });

      if (Dialog.checkAndShowError(res, err)) return;
      refresh(1);
    } catch (e) {}
  };

  return (
    <div className={classes.container}>
      <div className={classes.thumbnailContainer} ref={ref}>
        <div className={classes.thumbnail}>
          {data.content.pages.length > 0 && (
            <EditorProvider>
              <Frame data={data.content.pages[0].data} />
            </EditorProvider>
          )}
        </div>
        {user.role === "admin" && (
          <Link to={`/editor/${data.ID}-${toSlug(data.name)}`}>
            <div className={classes.editContainer}>
              <GoPencil fontSize={15} color="white" />
            </div>
          </Link>
        )}
      </div>
      <h4 className={classes.name} onDoubleClick={handleRename}>
        {data.name}
      </h4>
      <Link
        to={`/view/${data.ID}-${toSlug(data.name)}`}
        style={{ textDecoration: "none" }}
      >
        <Button.Solid full marginBottom={15}>
          View {EditorTheme.playbookAltText}
        </Button.Solid>
      </Link>
      {user.role === "admin" && (
        <div className={classes.twoButtons}>
          <Button.Outline full onClick={handleDuplicate}>
            Duplicate
          </Button.Outline>
          <Button.Outline full onClick={handleDelete}>
            Delete
          </Button.Outline>
        </div>
      )}
    </div>
  );
};

type StyleProps = {
  width: number;
};

const useStyles = createUseStyles((theme: TypeTheme) => ({
  container: {
    margin: 20,
  },
  thumbnailContainer: {
    width: "100%",
    position: "relative",
    height: (props: StyleProps) => props.width * 0.5658709,
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  thumbnail: {
    width: "100%",
    transform: (props: StyleProps) => `scale(${props.width / 1131})`,
    transformOrigin: "top left",
  },
  name: {
    fontWeight: "bold",
    textAlign: "center",
    height: 45,
    overflow: "hidden",
    cursor: "pointer",
  },
  editContainer: {
    position: "absolute",
    top: -20,
    left: -20,
    width: 40,
    height: 40,
    backgroundColor: theme.background,
    borderRadius: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  twoButtons: {
    "& > *:first-child": {
      marginBottom: 15,
    },
  },
  "@media (min-width: 950px)": {
    twoButtons: {
      display: "flex",
      "& > *:first-child": {
        marginRight: 10,
        marginBottom: 0,
      },
      "& > *:last-child": {
        marginLeft: 10,
      },
    },
  },
}));

export default PlaybookItem;
