import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useEditor, useNode, UserComponent } from "@craftjs/core";
import {
  createEditor,
  Editor,
  Element as SlateElement,
  Transforms,
} from "slate";
import { Slate, Editable } from "@craftjs/slate";
import { withReact } from "slate-react";
import { withHistory } from "slate-history";
import mergeRefs from "react-merge-refs";
import { MdFormatBold as BoldIcon } from "react-icons/md";
import { MdFormatItalic as ItalicIcon } from "react-icons/md";
import { MdLink as LinkIcon } from "react-icons/md";
import { MdFormatListBulleted as ListBulletedIcon } from "react-icons/md";
import { MdFormatListNumbered as ListNumberedIcon } from "react-icons/md";

import { Theme } from "theme";
import DragBox from "../../DragBox";
import SettingsButton, {
  SettingsButtonGroup,
} from "components/editor/settings/SettingsButton";
import EditorTheme from "themes";

const LIST_TYPES = ["NumberedList", "BulletedList"] as const;

type TLIST_TYPES_VALUE = typeof LIST_TYPES[number];

const Element = ({ render }: any) => render;

const EditableWrapper = ({
  attributes: { ref, ...attributes },
  children,
}: any) => {
  const { id } = useNode();
  const classes = useStyles({});

  return (
    <div
      data-craft-node={id}
      className={classes.editable}
      {...attributes}
      ref={dom => (ref.current = dom)}
    >
      {children}
    </div>
  );
};

interface RichTextEditorProps {
  mark?: "bold" | "italic" | null;
  block?: "BulletedList" | "NumberedList" | null;
  lineHeight: number;
}

const RichTextEditor: UserComponent<RichTextEditorProps> = ({
  mark = null,
  block = null,
  lineHeight,
}) => {
  const {
    connectors: { connect },
    selected,
  } = useNode(state => ({
    selected: state.events.selected,
  }));
  const [anySelected, setAnySelected] = useState(false);
  const classes = useStyles({ selected: anySelected, lineHeight });
  const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
  const {
    actions: { setProp },
  } = useNode();
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);
  const ref = useRef<HTMLDivElement>(null);
  const renderElement = useCallback(props => <Element {...props} />, []);

  useEffect(() => {
    if (!selected) setAnySelected(false);
  }, [selected]);

  useEffect(() => {
    const handle = (evt: MouseEvent) => {
      if (ref.current && enabled) {
        if (ref.current.contains(evt.target as HTMLElement)) {
          setAnySelected(true);
        } else {
          setAnySelected(
            sel =>
              sel &&
              document
                .getElementById("settings-panel")
                ?.contains(evt.target as HTMLElement),
          );
        }
      }
    };
    window.addEventListener("click", handle);
    return () => window.removeEventListener("click", handle);
  }, [enabled]);

  useEffect(() => {
    if (mark) {
      toggleMark(editor, mark);
      setProp(props => (props.mark = null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mark]);

  useEffect(() => {
    if (block) {
      toggleBlock(editor, block);
      setProp(props => (props.block = null));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [block]);

  return (
    <div
      className={classes.container}
      ref={mergeRefs([(ref: HTMLDivElement) => connect(ref), ref])}
    >
      <DragBox show={anySelected} />
      <Slate editor={editor}>
        <Editable
          as={EditableWrapper}
          readOnly={!enabled}
          renderElement={renderElement}
        />
      </Slate>
    </div>
  );
};

const RichTextEditorSettings = () => {
  const {
    actions: { setProp },
    lineHeight,
  } = useNode(node => ({ ...node.data.props }));

  const update = obj => {
    setProp(prop => {
      for (let key in obj) {
        prop[key] = obj[key];
      }
    });
  };

  return (
    <>
      <SettingsButtonGroup label="Formatting">
        <SettingsButton
          onMouseDown={evt => {
            evt.preventDefault();
            update({ mark: "bold" });
          }}
        >
          <BoldIcon />
        </SettingsButton>
        <SettingsButton
          onMouseDown={evt => {
            evt.preventDefault();
            update({ mark: "italic" });
          }}
        >
          <ItalicIcon />
        </SettingsButton>
        <SettingsButton
          onMouseDown={evt => {
            evt.preventDefault();
            update({ block: "BulletedList" });
          }}
        >
          <ListBulletedIcon />
        </SettingsButton>
        <SettingsButton
          onMouseDown={evt => {
            evt.preventDefault();
            update({ block: "NumberedList" });
          }}
        >
          <ListNumberedIcon />
        </SettingsButton>
        <SettingsButton
          onMouseDown={evt => {
            evt.preventDefault();
            update({ mark: "url" });
          }}
        >
          <LinkIcon />
        </SettingsButton>
      </SettingsButtonGroup>
      <SettingsButtonGroup label="Font Size" noMargin>
        {[10, 12, 14, 16, 18, 20, 22].map(size => (
          <SettingsButton
            key={size}
            onMouseDown={evt => {
              evt.preventDefault();
              update({ mark: { type: "size", data: size } });
            }}
          >
            {size}
          </SettingsButton>
        ))}
      </SettingsButtonGroup>
      <SettingsButtonGroup>
        {[24, 26, 28, 30, 32, 34, 36].map(size => (
          <SettingsButton
            key={size}
            onMouseDown={evt => {
              evt.preventDefault();
              update({ mark: { type: "size", data: size } });
            }}
          >
            {size}
          </SettingsButton>
        ))}
      </SettingsButtonGroup>
      <SettingsButtonGroup label="Font Colour">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            columnGap: "10px",
            rowGap: "10px",
          }}
        >
          {Object.values(EditorTheme.fontColor).map(color => (
            <SettingsButton
              key={color as string}
              onMouseDown={evt => {
                evt.preventDefault();
                update({ mark: { type: "color", data: color } });
              }}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: color as string,
              }}
            />
          ))}
        </div>
      </SettingsButtonGroup>
      <SettingsButtonGroup label="Line Height">
        {[0.6, 0.8, 1, 1.2, 1.4, 1.6].map(height => (
          <SettingsButton
            active={lineHeight === height}
            key={height}
            onMouseDown={evt => {
              evt.preventDefault();
              update({ lineHeight: height });
            }}
          >
            {height}
          </SettingsButton>
        ))}
      </SettingsButtonGroup>
    </>
  );
};

RichTextEditor.craft = {
  displayName: "Rich Text",
  props: {
    mark: null,
    block: null,
    lineHeight: 1.2,
  },
  related: {
    settings: RichTextEditorSettings,
  },
};

const isListType = (candidate: string): candidate is TLIST_TYPES_VALUE => {
  return LIST_TYPES.includes(candidate as TLIST_TYPES_VALUE);
};

const toggleBlock = (editor: Editor, format: SlateElement["type"]) => {
  const isActive = isBlockActive(editor, format);
  const isList = isListType(format);

  Transforms.unwrapNodes(editor, {
    match: n => {
      const type = !Editor.isEditor(n) && SlateElement.isElement(n) && n.type;
      if (typeof type === "boolean") return false;
      return isListType(type);
    },
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? "Typography" : isList ? "ListItem" : format,
  };
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (
  editor: Editor,
  mark: string | { type: string; data: any },
) => {
  let format: string;
  let data: any;
  if (typeof mark === "string") {
    format = mark;
    data = true;
  } else {
    format = mark.type;
    data = mark.data;
  }
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
    if (["color", "size"].includes(format)) {
      Editor.addMark(editor, format, data);
    }
  } else {
    if (mark === "url") {
      const url = window.prompt("Enter the URL");
      if (!url) return;
      Editor.addMark(editor, format, url);
    } else {
      Editor.addMark(editor, format, data);
    }
  }
};

const isBlockActive = (editor: Editor, format: string) => {
  const match = Editor.nodes(editor, {
    match: n =>
      !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  }).next().value;

  return !!match;
};

const isMarkActive = (editor: Editor, format: string) => {
  const marks = Editor.marks(editor);
  return marks ? Object.keys(marks).includes(format) : false;
};

interface StyleProps {
  selected?: boolean;
  fontSize?: number;
  lineHeight?: number;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: "relative",
    marginTop: 5,
    borderStyle: "dashed",
    borderColor: (props: StyleProps) =>
      props.selected ? "#338bdd" : "transparent",
    borderWidth: 2,
    lineHeight: (props: StyleProps) => props.lineHeight,
  },
  editable: {
    position: "relative",
    fontFamily: "Speedee, Arial, sans-serif",
    fontWeight: "normal",
    fontSize: "16pt",
    "& p": {
      margin: 0,
    },
  },
}));

export default RichTextEditor;
