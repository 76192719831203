import { createUseStyles } from "react-jss";
import { Theme } from "theme";

const useStyles = createUseStyles((theme: Theme) => ({
  element: {
    margin: [5, 0],
    listStyle: "none",
    "& li::before": {
      content: "'\\25AA'",
      color: theme.mcdonalds.red,
      "font-weight": "bold",
      display: "inline-block",
      width: "1em",
      marginLeft: "-1em",
    },
  },
}));

export const Typography = ({ element, attributes, children }: any) => {
  const Comp = element.data.variant || "p";
  return <Comp {...attributes}>{children}</Comp>;
};

Typography.craft = {
  displayName: "Text",
};

Typography.slate = {
  toCraftNode: (slateNode: any) => (craftNode: any) => {
    craftNode.data.props.variant = slateNode.data.variant;
  },
  toSlateNode: (craftNode: any) => (slateNode: any) => {
    slateNode.data = {
      ...(craftNode.data.props.variant
        ? { variant: craftNode.data.props.variant }
        : {}),
    };
  },
};

export const NumberedList = ({ element, attributes, children }: any) => {
  const classes = useStyles();
  return (
    <ol
      className={classes.element}
      data-craft-node={element.id}
      {...attributes}
    >
      {children}
    </ol>
  );
};

NumberedList.craft = {
  displayName: "Numbered List",
};

NumberedList.slate = {
  toSlateNode: () => (slateNode: any) => {
    slateNode.data = {};
  },
};

export const BulletedList = ({ element, attributes, children }: any) => {
  const classes = useStyles();
  return (
    <ul
      className={classes.element}
      data-craft-node={element.id}
      {...attributes}
    >
      {children}
    </ul>
  );
};

BulletedList.craft = {
  displayName: "Bulleted List",
};

BulletedList.slate = {
  toSlateNode: () => (slateNode: any) => {
    slateNode.data = {};
  },
};

export const ListItem = ({ element, attributes, children }: any) => {
  return (
    <li data-craft-node={element.id} {...attributes}>
      {children}
    </li>
  );
};

ListItem.craft = {
  displayName: "List Item",
};

ListItem.slate = {
  toSlateNode: () => (slateNode: any) => {
    slateNode.data = {};
  },
};
