import { Element } from "@craftjs/core";

import Cover from "./Cover";
import Page from "./Page";
import Quotation from "./Quotation";
import Chapter from "./Chapter";
import { TTemplate } from "type";

interface TemplateProps {
  type: TTemplate;
  variant?: string;
}

const Template: React.FC<TemplateProps> = ({ type, ...props }) => {
  switch (type) {
    case "cover":
      return <Element is={Cover} id="cover-template" canvas {...props} />;
    case "page":
      return <Element is={Page} id="page-template" canvas {...props} />;
    case "chapter":
      return <Element is={Chapter} id="chapter-template" canvas {...props} />;
    case "quotation":
      return (
        <Element is={Quotation} id="quotation-template" canvas {...props} />
      );
  }
};

export { Cover, Page, Quotation, Chapter };

export default Template;
