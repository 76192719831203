export interface IMarginStyleProps {
  margin?: any;
  marginRight?: any;
  marginLeft?: any;
  marginTop?: any;
  marginBottom?: any;
}

export const useStyleWithMarginProps = (props: any, styleObj: any) => {
  const style = Object.assign({}, styleObj);
  ["margin", "marginRight", "marginLeft", "marginTop", "marginBottom"].forEach(
    p => {
      if (!!props[p]) {
        style[p] = props[p];
      }
    },
  );
  return style;
};
