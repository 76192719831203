import { Frame, useEditor } from "@craftjs/core";
import { useEffect } from "react";
import { createUseStyles } from "react-jss";

import { useWindowSize } from "hooks";
import { Theme } from "theme";
import { TPlaybook } from "type";
import Template from "./templates";

// craftjs representation of <Element id="root" is="div" />, serialised
// we deserialise this to clear the screen, since it's a hassle to delete nodes individually
const clear = `{"ROOT":{"type":"div","isCanvas":false,"props":{"id":"root"},"displayName":"div","custom":{},"hidden":false,"nodes":[],"linkedNodes":{}}}`;

type TPages = TPlaybook["content"]["pages"];

interface SceneProps {
  pageNumber: number;
  activePage: TPages[number];
  onChange: (id: string, data: string) => void;
  padding?: number;
}

const Scene: React.FC<SceneProps> = ({
  pageNumber,
  activePage,
  onChange,
  padding = 700,
  children,
}) => {
  const { query, actions } = useEditor();
  const classes = useStyles();
  const { width } = useWindowSize();

  useEffect(() => {
    if (activePage) {
      try {
        if (activePage.data) {
          actions.deserialize(activePage.data);
        } else {
          actions.deserialize(clear);
          const nodeTree = query
            .parseReactElement(<Template type={activePage.template} />)
            .toNodeTree();
          actions.addNodeTree(nodeTree, "ROOT");
        }
      } catch (err) {
        console.error(err);
      }
    }
    return () => {
      if (activePage) onChange(activePage.id, query.serialize());
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePage]); // If the dependencies are [pageNumber, activePage]. It occurs the infinite loop when reorder page with new added content.

  return (
    <div
      className={classes.frame}
      style={{ transform: `scale(${(width - padding) / 1131})` }}
    >
      <Frame data={clear} />
      {children}
    </div>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  frame: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));

export default Scene;
