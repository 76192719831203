import { useEffect, useRef, useState } from "react";
import { createUseStyles } from "react-jss";
import { useEditor, useNode, UserComponent } from "@craftjs/core";
import shallow from "zustand/shallow";
import ContentEditable from "react-contenteditable";

import { Theme } from "theme";
import DragBox from "../DragBox";
import { usePlaybookStore } from "hooks";
import EditorTheme from "themes";
import SettingsButton, {
  SettingsButtonGroup,
} from "../settings/SettingsButton";

const Note: React.FC = () => {
  const contentEditableRef = useRef(null);

  const { id } = useNode(state => ({
    id: state.id,
  }));

  const [createComment, updateComment, getDetail, playbook, comments, page] =
    usePlaybookStore(
      state => [
        state.createComment,
        state.updateComment,
        state.getDetail,
        state.playbook,
        state.comments,
        state.page,
      ],
      shallow,
    );

  const [allComment, setAllComment] = useState(comments);

  const [text, setText] = useState<string>();

  const debounceRef = useRef<ReturnType<typeof setTimeout>>();
  const classes = useStyles({ selected: true });

  const playbookID = playbook?.ID;
  const pageID =
    !playbook || !playbook.content?.pages[page]
      ? ""
      : playbook.content?.pages[page].id;

  const comment = allComment.find(
    x => x.pageID === pageID && x.content.noteId === id,
  );
  const commentText = comment?.content.text;

  const [isOverHeight, setIsOverHeight] = useState(false);

  const [containerLimitedDimension, setContainerLimitedDimension] = useState({
    width: comment?.content.width || 0,
    height: comment?.content.height || 0,
  });

  useEffect(() => {
    if (contentEditableRef.current) {
      if (
        containerLimitedDimension.width === 0 &&
        containerLimitedDimension.height === 0
      ) {
        setContainerLimitedDimension({
          width: contentEditableRef.current.el.current.clientWidth,
          height: contentEditableRef.current.el.current.clientHeight,
        });
      }
    }
  }, [containerLimitedDimension.height, containerLimitedDimension.width]);

  useEffect(() => {
    if (!commentText !== null || commentText !== undefined) {
      setText(commentText);
    }
  }, [commentText]);

  useEffect(() => {
    if (window && window.location.pathname !== "/") {
      if (text !== undefined || text !== null) {
        debounceRef.current = setTimeout(async () => {
          if (comment) {
            if (comment.content !== text) {
              await updateComment({
                ID: comment.ID,
                content: {
                  noteId: id,
                  text,
                  // width: containerLimitedDimension.width,
                  // height: containerLimitedDimension.height,
                },
              });
            }
          } else {
            await createComment({
              playbookID,
              pageID,
              content: {
                noteId: id,
                text,
                // width: contentEditableRef.current.el.current.clientWidth,
                // height: contentEditableRef.current.el.current.clientHeight,
              },
            });
          }
        }, 1000);
      }
    }
    return () => clearTimeout(debounceRef.current);
  }, [text]);

  useEffect(() => {
    //  To get the updated comments every time switch between pages
    getDetail(playbookID).then(item => {
      if (item && item[0] && item[0].comments) {
        setAllComment(item[0].comments);
        // console.log(item[0].comments.find(x => x.pageID === pageID && x.content.noteId === id));
      }
    });
  }, [pageID]);

  useEffect(() => {
    if (text !== undefined || text !== null) {
      // Checking the height limit and
      // if (contentEditableRef.current) {
      //   if (
      //     contentEditableRef.current.el.current.clientHeight >
      //     containerLimitedDimension.height
      //   ) {
      //     setIsOverHeight(true);
      //     setText(text.substring(0, text.length - 5));
      //   } else {
      //     setIsOverHeight(false);
      //   }
      //   if (
      //     !isOverHeight &&
      //     contentEditableRef.current.el.current.clientWidth >
      //       containerLimitedDimension.width
      //   ) {
      //     // console.log("over width")
      //     let _text = text.slice(0, text.length - 3);
      //     let newline = "<br>";
      //     let lastChar = text.substring(text.length - 3);
      //     let fullContent = _text + newline + lastChar;
      //     setText(fullContent);
      //   }
      // }
      // debounceRef.current = setTimeout(async () => {
      //   if (comment) {
      //     if (comment.content !== text) {
      //       await updateComment({
      //         ID: comment.ID,
      //         content: { noteId: id, text },
      //       });
      //     }
      //   } else {
      //     await createComment({
      //       playbookID,
      //       pageID,
      //       content: { noteId: id, text },
      //     });
      //   }
      // }, 1000);
    }
    // return () => clearTimeout(debounceRef.current);
  }, [text, comment, createComment, updateComment, playbookID, pageID, id]);

  if (!pageID) return null;

  return (
    <ContentEditable
      ref={contentEditableRef}
      html={text ? text : ""}
      onChange={evt => {
        const text = evt.target.value
          .replace(/<div>/gi, "<br>")
          .replace(/<\/div>/gi, "");
        setText(text);
      }}
      tagName="p"
      className={classes.editable}
    />
  );
};

interface NoteLocationProps {
  borderWidth?: number | null;
  background?: string | null;
}

const NoteLocation: UserComponent<NoteLocationProps> = ({
  borderWidth,
  background,
}) => {
  const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));
  const {
    connectors: { connect },
    selected,
  } = useNode(state => ({
    selected: state.events.selected,
    id: state.id,
  }));
  const classes = useStyles({ selected, borderWidth, background });

  return (
    <div
      className={classes.container}
      ref={(ref: HTMLDivElement) => connect(ref)}
    >
      <DragBox show={selected} />
      <div className={classes.notes}>My Notes</div>
      <div className={classes.yellowBorder} id="notes">
        {!enabled && <Note />}
      </div>
    </div>
  );
};

const NoteSettings = () => {
  const {
    actions: { setProp },
    borderWidth,
  } = useNode(node => ({ ...node.data.props }));

  const update = obj => {
    setProp(prop => {
      for (let key in obj) {
        prop[key] = obj[key];
      }
    });
  };

  return (
    <>
      <SettingsButtonGroup label="Line Height">
        {[0, 5].map(_width => {
          let _mapping = {
            "0": "No border",
            "5": "With border",
          };
          return (
            <SettingsButton
              active={borderWidth === _width}
              key={_width}
              onMouseDown={evt => {
                evt.preventDefault();
                update({ borderWidth: _width });
              }}
            >
              {_mapping[_width.toString()]}
            </SettingsButton>
          );
        })}
      </SettingsButtonGroup>

      <SettingsButtonGroup label="Background Colour">
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr 1fr 1fr",
            columnGap: "10px",
            rowGap: "10px",
          }}
        >
          {Object.values(EditorTheme.fontColor).map(color => (
            <SettingsButton
              key={color as string}
              onMouseDown={evt => {
                evt.preventDefault();
                update({ background: color });
              }}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: color as string,
              }}
            />
          ))}
          <SettingsButton
            onMouseDown={evt => {
              evt.preventDefault();
              update({ background: "transparent" });
            }}
            style={{ width: "30px", height: "30px", border: "4px black solid" }}
          />
        </div>
      </SettingsButtonGroup>
    </>
  );
};

NoteLocation.craft = {
  displayName: "Note Box",
  props: {
    borderWidth: 5,
    background: null,
  },
  related: {
    settings: NoteSettings,
  },
};

interface StyleProps {
  selected?: boolean;
  background?: string;
  borderWidth?: number;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    flex: 1,
    position: "relative",
    marginTop: 5,
    borderStyle: "dashed",
    borderColor: (props: StyleProps) =>
      props.selected ? "#338bdd" : "transparent",
    borderWidth: 2,
    display: "flex",
  },
  notes: {
    display: (props: StyleProps) => (props.borderWidth ? "block" : "none"),
    position: "absolute",
    top: 3,
    left: 5,
    backgroundColor: EditorTheme.myNotesBoxBackgroundColor,
    padding: (props: StyleProps) => (props.borderWidth ? [5, 10] : 5),
    fontSize: 24,
    fontFamily: "Speedee, Arial, sans-serif",
    color: EditorTheme.myNotesBoxFontColor,
    fontWeight: "bold",
  },
  yellowBorder: {
    border: "5px solid",
    borderStyle: "solid",
    borderWidth: (props: StyleProps) => props.borderWidth,
    background: (props: StyleProps) => props.background,
    borderColor: EditorTheme.selectedColor,
    flex: 1,
    margin: (props: StyleProps) => (props.borderWidth ? 10 : 0),
    padding: (props: StyleProps) => (props.borderWidth ? [10, 20, 0] : 0),
    display: "flex",
  },
  editable: {
    flex: 1,
    fontSize: "14pt",
    fontFamily: "Arial, sans-serif",
    whiteSpace: "pre-wrap",
    margin: 15,
  },
}));

export default NoteLocation;
