import React from "react";
import convertBase64 from "utils/convertBase64";

interface FileInputPropsType {
  onChange: (item: any) => void;
  id: string;
  accept?: string;
}

const handleFileRead = async event => {
  const file = event.target.files[0];
  const base64 = await convertBase64(file);
  return base64;
};

const FileInput = ({ onChange, id, accept }: FileInputPropsType) => (
  <input
    id={id}
    style={{ display: "none" }}
    accept={accept}
    type="file"
    onChange={e => {
      handleFileRead(e).then(imageData => onChange(imageData));
    }}
  />
);

export default FileInput;
