import { useEditor, useNode } from "@craftjs/core";
import { createUseStyles } from "react-jss";
import { MdOpenWith as MoveIcon } from "react-icons/md";

import { Theme } from "theme";
import { useWindowSize } from "hooks";

interface DragBoxProps {
  show: boolean;
}

const DragBox: React.FC<DragBoxProps> = ({ show }) => {
  const {
    connectors: { drag },
  } = useNode();
  const { selected, enabled } = useEditor(state => {
    const currentNodeId = state.events.selected;
    let selected:
      | {
          id: string;
          name: string;
        }
      | undefined;

    if (currentNodeId) {
      selected = {
        id: currentNodeId,
        name:
          state.nodes[currentNodeId].data.displayName ??
          state.nodes[currentNodeId].data.name,
      };
    }

    return {
      selected,
      enabled: state.options.enabled,
    };
  });
  const { width } = useWindowSize();
  const classes = useStyles({ show });

  if (!enabled) return <div style={{ display: "none" }} />;

  return (
    <div
      className={classes.container}
      style={{ transform: `scale(${3 / (width / 1131)})` }}
    >
      <div ref={(ref: HTMLDivElement) => drag(ref)}>
        <MoveIcon />
      </div>
      <p>{selected?.name}</p>
    </div>
  );
};

interface StyleProps {
  show: boolean;
}

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    position: "absolute",
    top: -32,
    left: -2,
    height: 30,
    backgroundColor: "#000",
    opacity: (props: StyleProps) => (props.show ? 1 : 0),
    pointerEvents: (props: StyleProps) => (props.show ? "auto" : "none"),
    transition: "opacity 0.2s ease",
    borderRadius: [3, 3, 0, 0],
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: [0, 6],
    transformOrigin: "bottom left",

    "& p": {
      color: "white",
      margin: [0, 10],
      fontSize: 14,
      fontFamily: `-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
      "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
      sans-serif`,
      fontWeight: "normal",
    },

    "& > div": {
      pointerEvents: "inherit",
      cursor: "pointer",
      margin: [0, 3],
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },

    "& svg": {
      width: 16,
      height: "auto",
      fill: "#fff",
      pointerEvents: "inherit",
      cursor: "pointer",
    },
  },
}));

export default DragBox;
