import { useState, useEffect, useCallback } from "react";
import { useParams, useHistory } from "react-router-dom";
import { createUseStyles } from "react-jss";
import { Helmet } from "react-helmet-async";
// import { useEditor } from "@craftjs/core";
import shallow from "zustand/shallow";
import { generate } from "shortid";
import clsx from "clsx";
import { ErrorBoundary } from "react-error-boundary";

import ComponentsBar from "components/editor/ui/ComponentsBar";
import PagesPanel from "components/editor/ui/PagesPanel";
import SidebarToolsPanel from "components/editor/ui/SidebarToolsPanel";
import { usePlaybookStore } from "hooks";
import { TPlaybook, TTemplate } from "type";
import { Loading, NavRight, Button } from "components";
import Scene from "components/editor/Scene";
import useDeepMemo from "hooks/useDeepMemo";
import Modal from "components/modal/Modal";
import EditorProvider from "./EditorProvider";
import { Theme } from "theme";
import EditorTheme from "../../themes";

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    height: "calc(100vh - 70px)",
    display: "flex",
    flexDirection: "column",
  },
  title: {
    backgroundColor: "#333",
    color: "#fff",
    padding: [10, 10],
    fontSize: 24,
    height: 50,
  },
  editor: {
    height: "calc(100% - 50px)",
    display: "flex",
    flexDirection: "row",
  },
  centre: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#eee",
    overflow: "auto",
  },
  centered: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modal: {
    backgroundColor: "#fff",
    borderRadius: 3,
    padding: 10,
    width: "100%",
    maxWidth: 600,
    display: "flex",
    flexDirection: "column",
    justifyContent: "stretch",

    "& h1": {
      textAlign: "center",
    },
  },
  pageTypes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    margin: [15, 0],
  },
  pageType: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    cursor: "pointer",

    "& img": {
      width: 100,
      height: "auto",
      borderRadius: 3,
      border: "3px solid transparent",
    },

    "& p": {
      textAlign: "center",
      margin: [5, 0],
    },
  },
  active: {
    "& img": {
      borderColor: EditorTheme.selectedColor,
    },

    "& p": {
      fontWeight: "bold",
    },
  },
  label: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    fontWeight: "bold",
  },
  input: {
    display: "block",
    width: "100%",
    marginBottom: 10,
    marginTop: 5,
    height: 40,
    outline: "none",
    padding: "5px 10px",
    fontSize: 16,
  },
}));

const Editor = () => {
  // const { query } = useEditor();
  const history = useHistory();
  const classes = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [pageTitle, setPageTitle] = useState<string>("");
  const [pageType, setPageType] = useState<TTemplate>("page");
  const [playbook, setPlaybook] = useState<TPlaybook>();
  const [getDetail, updatePlaybook] = usePlaybookStore(
    state => [state.getDetail, state.updatePlaybook],
    shallow,
  );
  const { token } = useParams<{ token: string }>();
  const [activePage, setActivePage] = useState<number>(-1);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const [playbookLength, setPlaybookLength] = useState<number | undefined>();

  const [isCreated, setIsCreated] = useState(false);

  // For avoiding the error while reordering the page while the page content in playbook without data.
  // The solution is hard coded as the application switch the page between 2 pages, and get the page data by triggering the useEffect of activePage in Scene.tsx
  // If the reorder pages has data, there is no error.

  const length = playbook?.content?.pages?.length ?? 0;

  useEffect(() => {
    if (playbook && playbook.content && playbook.content.pages) {
      if (playbookLength === undefined) {
        setPlaybookLength(playbook.content.pages.length);
      } else if (playbook.content.pages.length > playbookLength) {
        // Act like a call back function while the new page is added
        // console.log("Page Added");
        setPlaybookLength(playbook.content.pages.length);
        setActivePage(playbook.content.pages.length - 1);
      } else if (playbook.content.pages.length < playbookLength) {
        setPlaybookLength(playbook.content.pages.length); // need to update the length of the playbook
        // Act like a call back function while the new page is removed
        // console.log('Page removed');
      }
    }
  }, [playbook]);

  useEffect(() => {
    (async () => {
      const id = (() => {
        try {
          const splitID = token.split("-")[0];
          return +splitID || 0;
        } catch (err) {
          return 0;
        }
      })();
      const [res] = await getDetail(id);
      if (!!res) {
        setPlaybook(res.detail);
        setPlaybookLength(res.detail.content.pages.length);
        if (res.detail.content.pages.length > 0) {
          setActivePage(0);
        }
      }
      setLoading(false);
    })();
  }, [token, getDetail]);

  const hasPages = playbook ? playbook.content.pages.length : true;

  useEffect(() => {
    if (!hasPages) setModalOpen(true);
  }, [hasPages]);

  const saveData = async (newContent: any) => {
    const updatedObj = {
      ...playbook,
      content: {
        pages: playbook.content.pages.map((page, i) =>
          i === activePage ? { ...page, data: newContent } : page,
        ),
      },
    } as TPlaybook;
    const [, err] = await updatePlaybook(updatedObj);
    if (err) return alert(err);
    sweetAlert("Saved succesfully");
  };

  const pages = playbook?.content.pages ?? [];

  const currentPage = useDeepMemo(() => pages[activePage], [pages, activePage]);

  const onChangeScene = useCallback((id, data) => {
    setPlaybook(playbook => ({
      ...playbook,
      content: {
        pages: playbook.content.pages.map(page =>
          page.id === id ? { ...page, data } : page,
        ),
      },
    }));
  }, []);

  if (loading)
    return (
      <div className={classes.centered}>
        <Loading />
      </div>
    );

  if (!playbook)
    return <div className={classes.centered}>No playbook found</div>;

  const backToList = () => {
    history.push("/");
  };

  const createNewPage = (name: string, template: TTemplate) => {
    setModalOpen(false);
    setPageTitle("");
    setPageType("page");
    setPlaybook(playbook => ({
      ...playbook,
      content: {
        pages: [
          ...playbook.content.pages,
          {
            id: generate(),
            name,
            template,
          },
        ],
      },
    }));
    setIsCreated(true);
  };

  const removePage = (id: string) => {
    if (activePage === playbook.content.pages.length - 1) {
      setTimeout(() => setActivePage(x => x - 1));
    }
    setPlaybook(playbook => ({
      ...playbook,
      content: {
        pages: playbook.content.pages.filter(page => page.id !== id),
      },
    }));
  };

  const reorderPages = (srcIndex: number, desIndex: number) => {
    const result = Array.from(playbook.content.pages);

    const [removed] = result.splice(srcIndex, 1);
    result.splice(desIndex, 0, removed);

    setActivePage(
      result.findIndex(
        page => page.id === playbook.content.pages[activePage].id,
      ),
    );

    setPlaybook(playbook => ({
      ...playbook,
      content: {
        pages: result,
      },
    }));
  };

  const renamePage = (id: string, name: string) => {
    setPlaybook(playbook => ({
      ...playbook,
      content: {
        pages: playbook.content.pages.map(page =>
          page.id === id ? { ...page, name } : page,
        ),
      },
    }));
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <title>Playbooks | Edit {playbook.name}</title>
      </Helmet>
      <Modal show={modalOpen} close={() => setModalOpen(false)}>
        <div className={classes.modal} onClick={evt => evt.stopPropagation()}>
          <h1>Create new page</h1>
          <div className={classes.pageTypes}>
            {EditorTheme.pages.map(item => (
              <div
                key={item.imageAlt}
                className={clsx(classes.pageType, {
                  [classes.active]: pageType === item.pageType,
                })}
                onClick={() => setPageType(item.pageType)}
              >
                <img src={item.imageSrc} alt={item.imageAlt} />
                <p>{item.text}</p>
              </div>
            ))}
            {/* <div
              className={clsx(classes.pageType, {
                [classes.active]: pageType === "cover",
              })}
              onClick={() => setPageType("cover")}
            >
              <img src={coverThumbnail} alt="cover page" />
              <p>Cover Page</p>
            </div>
            <div
              className={clsx(classes.pageType, {
                [classes.active]: pageType === "page",
              })}
              onClick={() => setPageType("page")}
            >
              <img src={pageThumbnail} alt="normal page" />
              <p>Normal Page</p>
            </div>

            <div
              className={clsx(classes.pageType, {
                [classes.active]: pageType === "chapter",
              })}
              onClick={() => setPageType("chapter")}
            >
              <img src={chapterThumbnail} alt="chapter" />
              <p>Chapter Page</p>
            </div>
            <div
              className={clsx(classes.pageType, {
                [classes.active]: pageType === "quotation",
              })}
              onClick={() => setPageType("quotation")}
            >
              <img src={quotationThumbnail} alt="quotation page" />
              <p>Quotation</p>
            </div> */}
          </div>
          <label className={classes.label}>
            Page name:
            <input
              type="text"
              placeholder="Page Name"
              value={pageTitle}
              onChange={evt => setPageTitle(evt.target.value)}
              className={classes.input}
            />
          </label>
          <Button.Solid full onClick={() => createNewPage(pageTitle, pageType)}>
            Create
          </Button.Solid>
        </div>
      </Modal>
      <NavRight>
        <Button.Solid bgColor={EditorTheme.linkColor} onClick={backToList}>
          Back to list
        </Button.Solid>
      </NavRight>
      <div className={classes.title}>{playbook.name}</div>
      <div className={classes.editor}>
        <EditorProvider enabled>
          <PagesPanel
            pages={playbook.content.pages}
            activePage={activePage}
            setActivePage={setActivePage}
            createNewPage={() => setModalOpen(true)}
            removePage={removePage}
            reorderPages={reorderPages}
            renamePage={renamePage}
          />
          <div className={classes.centre}>
            <ComponentsBar onSave={saveData} />
            <ErrorBoundary
              fallback={<div>There was an error displaying this page</div>}
            >
              <Scene
                pageNumber={activePage}
                activePage={currentPage}
                onChange={onChangeScene}
              />
            </ErrorBoundary>
          </div>
          <SidebarToolsPanel />
        </EditorProvider>
      </div>
    </div>
  );
};

export default Editor;
