import { useCallback, useState } from "react";

interface IStateUI {
  loading?: boolean;
  hasMore?: boolean;
  isLoadingMore?: boolean;
  errorMes?: string;
  btnText?: string;
}

export const useUIState = (
  initialState: IStateUI = undefined,
): [IStateUI, (newValue: IStateUI) => void] => {
  const [UIState, setUIState] = useState<IStateUI>(
    initialState || {
      hasMore: false,
      loading: false,
      isLoadingMore: false,
      errorMes: "",
      btnText: "",
    },
  );

  const setValue = useCallback(
    (obj: IStateUI) => setUIState(v => ({ ...v, ...obj })),
    [],
  );

  return [UIState, setValue];
};
