import useStore from "hooks/useStore";
import { CONFIG } from "./config";

export const changeAPISubdomain = (newSubdomain: string) => {
  if (typeof newSubdomain !== "string") return;
  CONFIG.SUBDOMAIN = newSubdomain;
};

const fetchApi = async (url: string, method?: string, body?: any) => {
  const token = useStore.getState().token;
  const response = await fetch(
    `https://${
      CONFIG.SUBDOMAIN ? CONFIG.SUBDOMAIN + "." : ""
    }api-guide.theatro360.com${url}`,
    {
      method: method ?? "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    },
  );
  const data = await response.json();
  return data;
};

export const uploadImage = async (file: File) => {
  const formData = new FormData();
  formData.append("file", file, file.name);
  const response = await fetch("https://api-guide.theatro360.com/api/upload", {
    method: "POST",
    redirect: "follow",
    body: formData,
  });
  const data = await response.json();
  return data;
};

export default fetchApi;
