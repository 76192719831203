import { useState, useEffect } from "react";
import ContentEditable from "react-contenteditable";
import { createUseStyles } from "react-jss";
import { useEditor } from "@craftjs/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  MdDelete as DeleteIcon,
  MdDragHandle as MoveIcon,
} from "react-icons/md";

import { TPlaybook } from "type";
import { Theme } from "theme";
import { Button, Dialog } from "components";
import EditorTheme from "themes";

interface TitleProps {
  active: boolean;
  title: string;
  onChange: (title: string) => void;
}

const Title: React.FC<TitleProps> = ({ active, title, onChange }) => {
  const [text, setText] = useState(title);
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    setText(title);
  }, [title]);

  useEffect(() => {
    if (!editing) {
      onChange(text);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  useEffect(() => {
    if (!active) {
      setEditing(false);
    }
  }, [active]);

  return (
    <ContentEditable
      tagName="p"
      html={text}
      disabled={!editing}
      onClick={() => setEditing(true)}
      onBlur={() => setEditing(false)}
      onKeyDown={evt => {
        if (evt.key === "Enter") {
          (evt.target as HTMLParagraphElement).blur();
        }
      }}
      onChange={evt => setText(evt.target.value)}
    />
  );
};

interface PagesPanelProps {
  pages: TPlaybook["content"]["pages"];
  activePage: number;
  setActivePage: React.Dispatch<React.SetStateAction<number>>;
  createNewPage?: () => void;
  removePage?: (page: string) => void;
  reorderPages?: (srcIndex: number, desIndex: number) => void;
  renamePage: (id: string, name: string) => void;
}

const PagesPanel: React.FC<PagesPanelProps> = ({
  pages,
  activePage,
  setActivePage,
  createNewPage,
  removePage,
  reorderPages,
  renamePage,
}) => {
  const classes = useStyles();
  const { enabled } = useEditor(state => ({ enabled: state.options.enabled }));

  return (
    <DragDropContext
      onDragEnd={param => {
        let srcIndex = param.source.index;
        let desIndex = param.destination?.index;

        if (desIndex !== undefined || desIndex < pages.length) {
          reorderPages(srcIndex, desIndex);
        }
      }}
    >
      <div className={classes.container}>
        <Droppable droppableId="droppable-1">
          {provided => (
            <div
              className={classes.pages}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {pages.map((page, index) => (
                <Draggable key={page.id} index={index} draggableId={page.id}>
                  {provided => {
                    const draggableStyle = {
                      ...provided.draggableProps.style,
                      borderColor:
                        index === activePage
                          ? EditorTheme.selectedColor
                          : "#ccc",
                    };
                    return (
                      <div
                        className={classes.page}
                        onClick={() => setActivePage(index)}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        style={draggableStyle}
                      >
                        <div className={classes.moveIcon}>
                          <div {...provided.dragHandleProps}>
                            <MoveIcon />
                          </div>
                        </div>
                        <Title
                          title={page.name}
                          active={activePage === index}
                          onChange={text => renamePage(page.id, text)}
                        />
                        {enabled && (
                          <DeleteIcon
                            className={classes.deleteIcon}
                            style={activePage === index ? { opacity: 1 } : {}}
                            onClick={async evt => {
                              evt.stopPropagation();
                              if (
                                await Dialog.confirmDelete(
                                  "Are you sure you want to delete this page?",
                                )
                              ) {
                                removePage(page.id);
                              }
                            }}
                          />
                        )}
                      </div>
                    );
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
        <Button.Solid onClick={createNewPage}>New Page</Button.Solid>
      </div>
    </DragDropContext>
  );
};

const useStyles = createUseStyles((theme: Theme) => ({
  container: {
    flex: 1,
    maxWidth: 300,
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    position: "relative",
  },
  pages: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "stretch",
    padding: [5, 0],
    overflow: "auto",
  },
  page: {
    border: "2px solid",
    borderRadius: 3,
    margin: [5, 10],
    padding: [5, 10],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",

    "& p": {
      margin: 0,
      cursor: "pointer",
      flex: 1,
      minHeight: 18,
    },

    "& svg": {
      width: 16,
      flexShrink: 0,
    },
  },
  moveIcon: {
    display: "flex",
    alignItems: "center",
    marginRight: 10,
    "& svg": {
      cursor: "grab",
    },
  },
  deleteIcon: {
    cursor: "pointer",
    marginLeft: 10,
    opacity: 0,
    transition: "opacity 0.2s ease",
    "&:hover": {
      opacity: 1,
    },
  },
}));

export default PagesPanel;
