import { useEffect, useRef, useState } from "react";

// straight from https://usehooks.com/useHover/

const useHover = (): [React.MutableRefObject<any>, boolean] => {
  const [value, setValue] = useState<boolean>(false);
  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseLeave = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseleave", handleMouseLeave);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseleave", handleMouseLeave);
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ref.current],
  );

  return [ref, value];
};

export default useHover;
